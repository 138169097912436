.slider-home__wrapper {
    .search-box, .menu-home, .slider__content {
        transition: opacity ease .3s, visibility ease .3s;
    }
    &--active {
        .search-box, .menu-home, .slider__content {
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }
}
.slider {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    .item {
        //padding-top: 39.75%;
        width: 100%;
        &:before {
            content: '';
            padding-top: 39.75%;
            display: block;
        }
    }
    .slider__picture {
        position: absolute;
        width: 100%;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        background: $color-black;
        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }
        > video {
            width: auto;
            height: 100%;
        }
        .slider__video-button {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);

            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $color-white;
            transition: background ease .3s;
            &:before {
                text-indent: 0;
                display: block;
                content: "\e075";
                font-family: icons-default;
                font-size: 30px;
                position: absolute;
                top: 50%;
                left: 55%;
                transform: translate(-50%, -50%);
                color: $color-2--2;
                transition: color ease .3s;
            }
            &--active {
                &:before {
                    content: "\e076";
                    left: 50%;
                }
            }
            &:hover, &:focus {
                background: $color-2--2;
                &:before {
                    color: $color-white;
                }
            }
        }
    }
    a:hover, a:focus {
        .title {
            text-decoration: underline;
        }
    }
    .prevCarrousel1 {
        position: absolute;
        left: 10px;
        top: 40%;
        transform: translateY(-50%);
        z-index: 100;
        button img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .nextCarrousel1 {
        position: absolute;
        right: 10px;
        top: 40%;
        transform: translateY(-50%);
        z-index: 100;
        button img {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .prevCarrousel1, .nextCarrousel1 {
        button {
            height: 80px;
            width: 30px;
        }
    }
    .slider__content {
        position: absolute;
        //top: 60%;
        bottom: 10%;
        left: 0;
    }
    .category {
        font-family: $typo-3;
        position: relative;
        display: inline-block;
        top: 50%;
        padding: 10px 16px;
        margin-left: 47px;
        font-size: 1.3em;
        color: $color-black;
        background-color: $color-2--0;
    }
    .title {
        margin-top: -18px;
        font-size: 3.2em;
        font-weight: $thin;
        width: 520px;
        color: $color-white;
        background-color: rgba(40, 40, 40, 0.5);
        padding: 30px 50px;
        &:after {
            font-family: icons-default;
            content: "\e08a";
            font-size: 1em;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .buttonStop__wrapper {
        float: left;
        display: inline-block;
        background-color: rgba(40, 40, 40, 0.5);
        position: relative;
        z-index: 100;
        top: -26px;
        padding: 5px 5px;
        img {
            vertical-align: middle;
        }
        .txt {
            font-family: $typo-1;
            color: $color-white;
        }
    }
}
