.subpages-menu {
    margin: 9em 0 3em;
    a {
        text-decoration: none;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    .title-1 {
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
    }
    .teaser-1 {
        font-weight: $light;
    }
}

.subpages-menu__listitems {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -2%;
    > * {
        flex: 0 1 29.3333%;
        margin: 0 2% 3em;
    }
}

.subpages-menu__picture {
    display: block;
    img {
        max-width: 100%;
        height: auto;
    }
    margin-bottom: 2em;
}

.subpages-menu__title {
    $subpages-menu__title__font-size: 1.6;
    margin: 0 0 em(.8, $subpages-menu__title__font-size) 0;
    font-size: #{$subpages-menu__title__font-size}em;
    font-family: $typo-3;
    text-transform: uppercase;
    color: $color-2--3;
    border: none;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
    position: relative;
    a {
        color: $color-2--3;
    }
    &:after {
        content: "";
        height: 1px;
        width: 77px;
        background-color: #d4d6d9;
        position: absolute;
        left: 0;
        bottom: -13px;
    }
}

.subpages-menu__listitems2 {
    margin: 1.5em 0;
}

.subpages-menu__item2 {
    $subpages-menu__item2__font-size: 1.4;
    font-size: #{$subpages-menu__item2__font-size}em;
    text-transform: uppercase;
    color: $color-black;
    margin: em(.5, $subpages-menu__item2__font-size) 0;
    @include default-icons-absolute-before('\e027', 14px, $color-2--2, 2px, inherit, inherit, 0);
    padding-left: em(1.5, $subpages-menu__item2__font-size);
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}
