// @name Search box
// @description Search module

.search-box {
    float: unset;
    top: 87px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .search-box__fields-wrapper {
        position: relative;
    }
    .ddm {
        > .ddm__sub-level {
            transform: none;
            top: unset;
            right: unset;
            height: unset;
            width: unset;
            .button__background {
                display: block;
            }
        }
    }
    .ddm--off {
        > .button__background {
            display: none;
        }
    }
    .text-help {
        display: none;
    }
}

.search-box.home-search {
    width: auto;
    position: absolute;
    top: 87px;
    padding: 0 0;
    .search-box__wrapper {
        display: table;
    }
    .search-box__field-wrapper--in-line label {
        margin-bottom: 0;
    }
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .search-box__fields-wrapper {
        width: 100%;
        padding-left: 35px;
    }
    // Drop down menu
    .ddm {
        > .ddm__sub-level {
            padding: 1em;
            background: $color-3--2;
        }
    }
}
