.slide-bar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    transform: translate(100%,0);
    width: 400px;
    z-index: 1001;
    transition: transform .3s linear;
    .slide__wrap {
        width: 100%;
        position: relative;
        height: 100%;
    }
    .background__wrapper {
        content: "";
        width: 450px;
        height: 100%;
        background: url('../Images/bg/bg-border-1.png') 100% 100% repeat;
    }
    .ddm {
        height: 100%;
        .button__background {
            background: url('../Images/bg/bg-border-1.png');
            height: 145px;
            width: 145px;
            border-radius: 50%;
            position: absolute;
            right: 390px;
            top: 50vh;
            transform: translateX(0);
            transition: all ease 0.3s;
        }
        &.slide-bar__wrapper {
            .ddm__sub-level {
                display: block;
                visibility: hidden;
                top: 0;
                left: -380px;
                right: inherit;
                z-index: 2;
                width: 40em;
                height: 100%;
                background-color: $color-white;
                transform: translate(100%,0);
                transition: transform ease 0.3s;
            }
            .ddm__wrapper {
                background-color: $color-white;
                padding: 25px 25px;
                height: 100%;
                transition: all ease .3s;
                &:before {
                    content: "";
                    height: 100%;
                    width: 8px;
                    background: url('../Images/bg/bg-border-1.png') 100% 100% repeat;
                    position: absolute;
                    top: 0;
                    left: -8px;
                }
            }
            &.ddm--active {
                transform: translate(0,0);
                transition: all ease .6s;
                .ddm__sub-level {
                    visibility: visible;
                    transform: translate(0,0);
                    transition: transform ease 0.3s;
                    z-index: -1;
                }
                .button__background{
                    right: 59px;
                    transform: translateX(-709px);
                    transition: all ease 0.3s;
                }
                .ddm__button {
                    background-color: $color-white;
                    .text-button {
                        color: $color-1--3;
                    }
                    svg {
                        fill: $color-black;
                        //stroke: $color-black;
                        //stroke-width: 10px;
                    }
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 1px;
                        height: 120px;
                        top: 0;
                        right: -14px;
                        border: 8px solid $color-white;
                    }

                }
            }
        }
    }
    .ddm__title {
        position: absolute;
        width:150px;
        height:150px;
        right: 150px;
        overflow: hidden;
        transition: transform 0.3s linear;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        &:hover {
            span {
                text-decoration: underline;
                color: $color-black;
                transition: color ease .3s;
            }
        }
        span {
            font-family: $typo-3;
            font-size: 0.8em;
            font-weight: $bold;
            text-transform: uppercase;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            top: 69%;
            color: $color-white;
            transition: color ease .3s;
        }
        .svg-icon {
            width: 46px;
            height: 44px;
            position: relative;
            top: -10px;
            transition: stroke ease .3s;
            fill: $color-white;
            stroke: $color-white;
            stroke-width: 0px;
        }
    }
    .ddm__button {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 1.6em;
        background: $color-1--3;
        height: 12.5rem;
        width: 12.5rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform :translateX(-50%) translateY(-50%);
        transition: background ease .3s, height ease .3s, width ease .3s;
        &:before {
            content: none;
        }
        &:hover, &:focus {
            color: $color-black;
            text-decoration: underline;
            height: 13.5rem;
            width: 13.5rem;
            transition: background ease .3s, height ease .3s, width ease .3s;
            background-color: $color-white;
            .svg-icon {
                transition: stroke ease .3s;
                fill: $color-black;
            }
        }
    }
    .slide__bar-title {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 3em;
        text-transform: uppercase;
        padding-bottom: 30px;
        padding-top: 20px;
        padding-right: 50px;
    }
    .slider__picture {
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 20px;
        }
    }
    .title {
        color: $color-1--3;
        font-family: $typo-3;
        font-size: 1.6em;
        font-weight: $bold;
        margin-bottom: 25px;
    }
    .slide-bar__content {
        font-size: 1.4em;
    }
    ul li {
        line-height: 1.7em;
    }
    .button-1 {
        position: absolute;
        top: 50px;
        right: 53px;
        padding: 1.2em 1.2em;
        &:before {
            content: '\e022';
            color: $color-white;
            font-size: 1.6em;
            left: 5px;
        }
    }
    .button-2 {
        margin-top: 20px;
    }
}

@media (max-width: #{$tablet}px) {
    .slide-bar {
        display: none;
    }
}
@media (max-width: #{$smartphone}px) {
    .slide-bar {

    }
}
