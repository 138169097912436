// @name bloc news

// @name Bloc news in column
.bloc-news {
    img {
        max-width: 100%;
        height: auto;
    }
    .bloc-news__title {
        $bloc-news__title__font-size: 1.8;
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$bloc-news__title__font-size}em;
        margin: -15px 0 em(1.5, $bloc-news__title__font-size);
        color: $color-black;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    .category {
        font-size: 1.2em;
        color: $color-black;
        background-color: $color-2--0;
        display: inline-block;
        padding: 8px 10px;
        position: relative;
        top: -31px;
    }
}
