.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {

}

.home {
    .menu-main-1__wrapper {
        max-width: unset;
    }
}

.menu-main-1 {
    @extend .wrapper-main;
}

.menu-main-1__item--active .menu-main-1__listitems {
    .menu-main-1__header a:hover {

    }
}

.menu-main-1__item {
    text-decoration: none;
    > .menu-main-1__header {
        a {
            display: block;
        }
    }
    > .menu-main-1__sublevel {

    }
    &.menu-main-1__item--current {
        text-decoration: underline;
        background-color: $color-1--3;
        color: $color-white;
        > .menu-main-1__header a {
            color: $color-white;
            &:before {
                border-right: 1px solid $color-2--2;
            }
        }
    }
}

.menu-main-1__listitems-1 > .menu-main-1__item--active > .menu-main-1__header > a:before {
    content: "\e019";
}

.menu-main-1__closing-button {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    top: .5rem;
    right: 20rem;
    width: 30px;
    height: 30px;
    background: transparent;
    &:before {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%);
        font-family: icons-default;
        content: "\e022";
        font-size: 2em;
        font-weight: $bold;
        text-indent: 0;
        line-height: 0;
        display: block;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    margin-top: 20px;
    > li {
        > .menu-main-1__header {
            display: none;
        }
        > .menu-main-1__sublevel {
            display: block;
            > .menu-main-1__closing-button {
                display: none;
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    > li {
        display: inline-block;
        vertical-align: middle;
        padding: 0px 2rem 0 4rem;
        transition: background ease 0.3s, color ease 0.3s;
        > .menu-main-1__header {
            > a {
                color: $color-1--3;
                font-size: 1.5em;
                text-transform: uppercase;
                font-weight: $bold;
                padding: em(1, 2) 0;
                @include default-icons-absolute-before('\e018', 1.4rem, $color-2--2, 10px, inherit, inherit, -29px);
                &:before {
                    padding-right: 6px;
                    border-right: 1px solid $color-1--3;
                }
            }
        }
        &:hover, &:focus {
            background-color: $color-1--3;
            transition: background ease 0.5s, color ease 0.5s;
            a {
                color: $color-white;
                &:before {
                    border-right: 1px solid $color-2--2;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 100;
            //top: 65px;
            top: 85px;
            left: 0;
            right: 0;
            width: 100vw;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            .menu-main-1__listitems-1__wrapper {
                padding: 2em 0;
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
                background: $color-1--3;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 1000px;
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                }
            }
            a, button, p {
                color: $color-white;
                &:focus {
                    outline-color: $color-white;
                }
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: inherit;
    height: auto;
    transition: height ease .3s;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -20px;
    > li {
        flex: 1 0  calc(25% - 20px);
        margin: 0 10px;
        display: block;
        // Level 2 and 3
        .menu-main-1__header {
            position: relative;
            .menu-main-1__action {
               display: none;
            }
        }
        ul li {
            .menu-main-1__header {
                .menu-main-1__action {
                    display: unset;
                }
            }
        }
            a {
                font-size: 1.8em;
                text-transform: uppercase;
                font-weight: $bold;
                padding-right: 0.5rem;
                margin-bottom: 15px;
                display: inline-block;
                transition: background ease 0.3s, color ease 0.3s;
                z-index: 20;
            }
        > .menu-main-1__sublevel {
            display: block;
           // position: absolute;
            position: relative;
            left: 0;
            top: -20px;
        }

    }
    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding: 1em 0;
    display: table-cell;
    > li {

        margin: 0.8em 0;
        .menu-main-1__header {
            position: relative;
            .menu-main-1__action {
                position: absolute;
                top: 0;
                z-index: 7;
                text-indent: -9999px;
                text-align: left;
                white-space: nowrap;
                width: 16.5px;
                height: 16.5px;
                background: $color-2--0;
                border: 1px solid $color-2--0;
                &:before {
                    font-family: icons-default;
                    content: "\e08a";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    font-size: 1em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: normal;
                    display: block;
                    margin: 0;
                    background-color: $color-2--0;

                }
                &[aria-expanded="true"] {
                    border: 1px solid $color-2--0;
                    &:before {
                        content: "\e023";
                        background-color: $color-1--3;
                        color: $color-2--0;
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                font-size: 1.4em;
                font-weight: $normal;
                padding-left: 1.5em;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            visibility: hidden;
            transform: scaleY(0);
            transform-origin: 0 0;
            transition: visibility ease 0s .2s;
            &[aria-hidden="false"] {
                transition: transform ease .4s, visibility ease 0s 0s;
                visibility: visible;
                transform: scaleY(1);
                position: relative;
            }
        }
    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: 1em;
    > li {
        margin: 0.8em 0;
        > .menu-main-1__header {
            a {
                font-size: 1.2em;
                &:before {
                    position: absolute;
                    top: 2px;
                    left: -8px;
                    transform: translate(-50%);
                    font-family: icons-default;
                    content: "\e011";
                    font-size: 1em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                    padding: 7px 2px;
                    height: 16px;
                    width: 16px;
                    border-right: unset;
                }
            }
        }
    }
    .menu-main-1__item {
        &.menu-main-1__item--current {
            > .menu-main-1__header a {
                &:before {
                    border-right: unset;
                }
            }
        }
    }

}

.menu-main-1__listitems-1 {
    > li {
        &:hover, &:focus {
            .menu-main-1__listitems-4 {
                li {
                    .menu-main-1__header {
                        a {
                            border-right: unset;
                        }
                    }
                }
            }
        }
    }
}
