.site-infos {
    p {
        text-align: center;
        padding-top: 15px;
    }

    .site-infos__img-wrap {
        float: none;
        margin-right: 0;
        text-align: center;
    }

    .hour-place {
        text-align: center;
    }
}

.site-infos-contact {
    .list-infos__item {
        padding-left: 0;
    }

    .site-infos-contact__wrapper {
        text-align: center;
        padding-left: 0;
        padding-bottom: 10px;
    }

    .site-infos-contact__social-networks {
        justify-content: center;
    }

    .site-infos-contact__wrapper .list-infos__item:before {
        position: unset;
        display: inline-block;
    }
}

.menu-cross {
    padding-left: 30%;
    position: relative;

    ul {
        padding-left: 0;
    }
}
