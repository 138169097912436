// Galerie classique (avec vignettes) + Galerie album

// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
    margin-right: -10px;
    margin-left: -10px;
    position: relative;
    &__title {
        padding: 0 10px;
    }
    &__nb-items {
        display: block;
        padding: 0 10px;
        @include background-opacity($color-1--3, .8);
        color: $color-white;
        position: absolute;
        z-index: 10;
        bottom: 0;
        right: 0;
        font-size: 3em;
        margin: 0;
    }
}

.slider-galerie {
    margin-bottom: 0;
    &__item {
        height: auto;
        display: none;
        &:first-child {
            display: block;
        }
    }
    .infos-img {
        display: none;
        position: static;
        right: inherit;
        bottom: inherit;
        background: #000;
    }
    a {
        display: inline-block;
        text-decoration: none;
        padding: 0;
    }
    img {
        max-height: inherit;
        position: static;
        top: inherit;
        bottom: inherit;
        left: inherit;
        right: inherit;
        transform: inherit;
        display: inline;
    }
}

.carousel-galerie__thumb, .carousel-galerie__pager {
    display: none;
}

.classical-gallery__video {
    iframe {
        width: 100%;
        max-height: inherit;
        position: static;
        top: inherit;
        bottom: inherit;
        left: inherit;
        right: inherit;
        transform: inherit;
        display: inline;
    }
    .link-all-videos {
        font-family: $typo-2;
        font-weight: 400;
        font-size: 1.6em;
        display: block;
        color: $color-white;
        height: 35px;
        background: transparent;
        text-align: right;
        padding-right: 70px;
        z-index: 12;
        position: relative;
        top: 8px;
    }
    .classical-gallery__nb-items {
        bottom: 35px;
        text-align: right;
        position: relative;
    }
}
