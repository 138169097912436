.go-to-top {
    margin-top: 0;
    display: block;
    width: 100%;
    right: 1%;
    bottom: 0;
    z-index: 7000;
    p {
        text-align: right;
    }
    a {
        &:before {
            content: "\e015";
        }
    }
    .go-to-top__wrapper {
        background: url('../Images/bg/bg-border-1.png');
        height: 63px;
        width: 63px;
        border-radius: 50%;
        display: block;
        position: absolute;
        right: 1%;
        top: -75px;
    }
    .button-1 {
        background: $color-2--0;
        &.button-1--no-text {
            padding: 1.4em 1.75em;
            right: 50%;
            transform: translateX(50%) translateY(-50%);
            top: 50%;
            &:before {
                color: $color-black;
                left: 42%;
            }
        }
        &:hover {
            &:before {
                color: $color-white;
            }
        }
    }
}
