.slider  {
    .title {
        font-size: 1.8em;
        &:after {
            position: absolute;
            right: 45%;
            transform: translateX(-50%);
            top: unset;
            bottom: 7px;
        }
    }
    .category {
        margin-left: 0;
        left: -55px;
    }
    .slider__content {
        position: relative;
    }
    .buttonStop__wrapper {
        position: relative;
        top: 0;
    }
    .prevCarrousel1, .nextCarrousel1 {
        bottom: 0;
        button {
            height: 80px;
            width: 27px;
            img {
                height: 16px;
            }
        }
    }
}
