// Newsletter inscription
.newsletter {
    .newsletter__title {
        margin-bottom: .5em;
    }
    form {
        margin: 0;
    }
    fieldset {
        margin-bottom: .5em;
    }
    /*
    input {
      padding-top: em(1, $input__font-size);
      padding-bottom: em(1, $input__font-size);
    }
    */
}

.newsletter__fields-wrapper, .newsletter__button-wrapper {
    display: inline-block;
    vertical-align: bottom;
}

.newsletter__links {

}
