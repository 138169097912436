// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
    .bloc-contact__title {
        $bloc-contact__title__font-size: 1.4;
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$bloc-contact__title__font-size}em;
        color: $color-black;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    .category {
        text-transform: lowercase;
    }
    .list-infos {
        font-family: $typo-1;
        font-weight:$light;
        .list-infos__item.list-infos__address {
            padding-left: 0;
            &:before {
                content: "";
            }
        }
    }
    .list-infos__wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        > * {
            flex: inherit;
        }
        .list-infos__listitems + .list-infos__listitems {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
        }
    }
    .button-2 {
        margin-top: em(1, $button-2__font-size);
        color: $color-white;
        background: $color-1--3;
        &:before {
            content: "\e02a";
            color: $color-white;
        }
        &:hover {
            color: $color-2--2;
            background: $color-black;
            &:before {
                color: $color-2--2;
            }
        }
    }
}
