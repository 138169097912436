.events {
    .events__wrapper {
        display: block;
        margin: 0 0;
    }
    .title-1 {
        padding: 0 10px;
        &:after {
            min-width: 20%;
        }
    }
    .bloc-event {
        display: block;
        width: 100%;
        margin: 0 0;
        .category, &__title, .hour-place {
            text-align: center;
        }
        .date-1 {
            text-align: center;
            position: relative;
            top: -44px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .bloc-event .bloc-event__wrapper-date {
        width: 100%;
        margin-left: 0;
    }
    .bloc-event__picture {
        display: block;
        margin: 0 1%;
    }
    .bloc-event__wrapper {
        display: block;
        padding-left: 0;
        left: unset;
        margin-top: 7em;
    }
    .bloc-event__picture + .bloc-event__wrapper  {
        .bloc-event__wrapper-date {
            width: 100%;
        }
        .date-1 {
            left: 50%;
        }
    }
    .hour-place:before {
        left: 50%;
        transform: translateX(-50%);
    }
    .link-view-2 {
        right: 15px;
    }
}

.bloc-event img {
    width: 100%;
}
