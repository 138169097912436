// @name Go so far
// @description Sections content below the content

.go-so-far__wrapper {
    flex-wrap: wrap;
    > * {
        flex-basis: 100%;
        padding-left: 0;
    }
}

