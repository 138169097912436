.reports {
    padding-top: 8em;
    padding-bottom: 7em;
    background-color: $color-3--4;
    &.shadow__wrapper {
        padding: 80px 0 80px 0;
        &:before {
            left: 10%;
            transform: rotate(-2deg);
            bottom: 28px;
            box-shadow: 0px 21px 15px 7px $color-3--2;
        }
        &:after {
            right: 10%;
            transform: rotate(2deg);
            bottom: 28px;
            box-shadow: 0px 21px 15px 7px $color-3--2;
        }
    }
    .title-1 {
        color: $color-white;
        margin-bottom: 10px;
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
    }
    .reports__filter {
        color: $color-white;
        font-size: 2em;
        text-align: center;
        margin-bottom: 30px;
        > a {
            font-size: 1em;
            font-family: $typo-3;
            color: $color-white;
            text-transform: uppercase;
            font-weight: $normal;
            &:hover, &:focus {
                text-decoration: underline;
            }
            &.reports__filter--active {
                color: $color-2--1;
            }
        }
    }
    .reports__wrapper {
        position: relative;
        margin: 0 -1%;
    }
    .reports__listitem {
        display: flex;
    }
    .report__item {
        flex-basis: 33%;
        display: block;
        a {
            display: block;
            width: 100%;
            overflow: hidden;
            position: relative;
            &:hover, &:focus {
                .item__content {
                    top: 0;
                }
                .video:after {
                    text-decoration: none;
                }
            }
        }
    }
    .reports__items--small {
        display: flex;
        flex-wrap: wrap;
        min-width: 400px;
        img {
            width: 200px;
            height: 155px;
        }
    }
    .item__content {
        h3 {
            font-size: 1.8em;
            font-weight: $bold;
            text-transform: uppercase;
        }
        .item__content--description {
            position: absolute;
            top: 26%;
            width: 100%;
            padding: 0 10px;
            color: $color-white;
            box-sizing: border-box;
            font-size: 1.3em;
            font-weight: $light;
        }
        .button-1 {
            font-size: 1.5em;
            background-color: transparent;
            color: $color-white;
            font-weight: $bold;
            margin-top: 30px;
            &:before {
                content: "\e000";
                background-color: $color-2--2;
                color: $color-1--3;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                padding-top: 10px;
                left: 23px;
                font-size: 0.6em;
            }
        }
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 401px;
        min-height: 310px;
        background: rgba(0, 163, 178, .7);
        transition: top ease .3s .1s;
        &.item__content--small {
            width: 200px;
            min-height: 155px;
        }
    }
    .item__content--small {
        > .item__content--description {
            font-size: 1em;
            h3 {
                font-size: 1.3em;
            }
            .button-1 {
                margin-top: 5px;
                font-size: 1em;
                &:before {
                    height: 20px;
                    width: 20px;
                    padding-top: 7px;
                    left: 8px;
                    font-size: 0.6em;
                }
            }
        }
    }
    .video {
        &:after {
            font-family: icons-default;
            content: "\e027";
            font-size: 2em;
            color: $color-white;
            background-color: $color-2--2;
            height: 40px;
            width: 40px;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding-left: 12px;
            padding-top: 7px;
        }
    }
    .link-view-2 {
        position: absolute;
        right: 30px;
        top: -104px;
        .button-1 {
            background-color: $color-1--2;
            &:hover {
                background-color: $color-2--2;
            }
            &.button-1--no-text {
                background-color: $color-2--2;
                &:hover {
                    background-color: $color-1--2;
                }
            }
        }
    }
}
