// @name bloc event

// @name Bloc event in column
.bloc-event {
    img {
        max-width: 100%;
        height: auto;
    }
    .bloc-event__wrapper-date {
        margin-bottom: 2em;
    }
    .bloc-event__title {
        $bloc-event__title__font-size: 1.6;
        font-family: $typo-1;
        font-weight: $light;
        font-size: #{$bloc-event__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-event__title__font-size);
        color: $color-black;
        text-align: center;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    .date-1 {
        text-align: center;
        position: relative;
        top: -44px;
        span:first-child + time {
            margin-left: 0;
        }
    }
    .hour-place {
        color: $color-3--3;
        text-align: center;
        font-weight: $light;
        position: relative;
        &:before {
            content: "";
            height: 1px;
            width: 77px;
            background-color: $color-3--6;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -9px;
        }
    }
    .category {
        color: $color-2--3;
        text-align: center;
        margin-top: -20px;
    }
    .link-view {
        text-align: center;
    }
}
