// @name Main menu
.menu-main {
    color: $color-1--3;
    padding-top: 1em;
    ul {
        text-align: center;
        display: inline-block;
        padding-left: 1em;
    }
    a {
        color: $color-1--3;
        font-size: 1.5em;
        text-transform: uppercase;
        display: block;
        padding: 1rem 0 1rem 1rem;
        font-weight: $bold;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 2.5rem;
        @include default-icons-absolute-before('\e018', 1.4rem, $color-2--2, 15px, inherit, inherit, 5px);
        &:before {
            padding-right: 8px;
            border-right: 1px solid $color-2--2;
        }
        transition: background ease 0.3s, color ease 0.3s;
        &:hover {
            background-color: $color-1--3;
            transition: background ease 0.5s, color ease 0.5s;
            a {
                color: $color-white;
            }
        }
    }
    &__level-1--active{
        background-color: $color-1--3;
        a {
            color: $color-white;
        }
    }
}
