.cartographie {
    &__nav {
        width: 21.9rem;
        min-width: 21.9rem;
        ul {
            font-size: 1.2rem;
            li {
                a {
                    .wrap-icon {
                        width: 3.5rem;
                        min-width: 3.5rem;
                        margin-right: 1.1rem;
                        svg {
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
}
