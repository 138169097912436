.cartographie {
    margin-top: 8rem;
    .title-1 {
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        span {
            flex: 1 1 content;
            display: block;
        }
    }
    &__wrapper {
        margin-top: 6.5rem;
        display: flex;
    }
    &__nav {
        width: 31.1rem;
        min-width: 31.1rem;
        ul {
            font-size: 1.4rem;
            text-transform: uppercase;
            li {
                a {
                    width: 100%;
                    height: 7rem;
                    padding: 1.8rem;
                    color: $color-white;
                    background-color: $color-1--3;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    transition: all 0.3s ease;
                    .wrap-icon {
                        display: inline-block;
                        width: 3.7rem;
                        min-width: 3.7rem;
                        height: 100%;
                        margin-right: 2rem;
                        position: relative;
                        border-right: 1px solid rgba(255, 255, 255, 0.2);
                        transition: all 0.3s ease;
                        svg {
                            width: 80%;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            fill: $color-white;
                            transition: all 0.3s ease;
                            stroke: $color-white;
                        }
                    }
                    &:hover,
                    &:focus {
                        color: $color-3--4;
                        background-color: $color-2--0;
                        .wrap-icon {
                            border-color: rgba(40, 40, 40, 0.2);
                            svg {
                                fill: $color-3--4;
                            }
                        }
                    }
                }
                &:last-child {
                    a {
                        color: $color-2--0;
                        background-color: $color-3--4;
                        &:hover,
                        &:focus {
                            color: $color-3--4;
                            background-color: $color-2--0;
                            .wrap-icon {
                                svg {
                                    fill: $color-3--4;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__picture {
        position: relative;
        flex: 1;
        picture {
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }
        }
        &-link {
            width: 11rem;
            height: 11rem;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $color-3--0;
            img {
                display: block;
                margin: 0.5rem auto 0;
            }
        }
    }
}
