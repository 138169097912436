.events {
    position: relative;
    .title-1 {
        &:before {
            flex: unset;
            min-width: 35%;
        }
        &:after {
            flex: unset;
            min-width: 30%;
        }
    }
    .events__wrapper {
        display: block;
        margin: 0 0;
    }
    .bloc-event {
        display: table;
        width: 100%;
        margin: 0 0;
        margin-bottom: 4em;
        .category, &__title, .hour-place, .date-1 {
            text-align: left;
        }
    }
    .bloc-event__picture {
        display: table-cell;
    }
    .hour-place:before {
        left: 0;
        transform: none;
    }
    .bloc-event .bloc-event__wrapper-date {
        width: 90%;
    }
    .bloc-event__wrapper {
        display: table-cell;
        vertical-align: middle;
        padding-left: 75px;
        max-width: 100%;
        position: relative;
        top: unset;
        transform: none;
        .bloc-event__wrapper-date {
            width: 60%;
            margin-left: 20%;
        }
        .date-1 {
            left: 10%;
        }
    }
    .bloc-event__picture + .bloc-event__wrapper {
        .bloc-event__wrapper-date {
            width: 90%;
            margin-left: 0;
        }
        .date-1 {
            left: -58px;
        }

    }
    .category {
        margin-top: 0;
    }
    .date-1 {
        top: 50%;
        transform: translateY(-50%);
        left: -58px;
        position: absolute;
    }
    .bloc-event__title {
        font-size: 2.2em;
    }
    .link-view-2 {
        top: -70px;
        right: 35px;
    }
}
