.list-type-1--event {
    .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
    }
    .list-type-1__picture {
        float: none;
    }
    .list-type-1__wrapper-date {
        padding-right: 2em;
        width: 14em;
        border-right: 1px solid $color-3--3;
    }
    .list-type-1__wrapper {
        padding-left: 2em;
    }
    .bloc-event {
        &:hover {
            .bloc-event__picture img {
                filter: grayscale(70%);
            }
        }
    }
    .category {
        color: $color-2--3;
    }
    .date-1 {
        font-family: $typo-1;
        font-weight: $bold;
        color: $color-1--3;
        text-align: center;
        time {
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            margin: 0 -0.14em;
        }
        .start {
            background-color: $color-1--3;
            padding: 13px 13px;
        }
        .end {
            background-color: $color-1--5;
            padding: 13px 13px;
        }
        .day, .month, .year {
            display: block;
            line-height: 1;
            color: $color-white;
        }
        .day {
            font-size: 2.8em;
            font-weight: $bold;
        }
        .month, .year {
            font-size: 1.2em;
            font-weight: $light;
            text-transform: uppercase;
        }
    }
}
.section-main .list-type-1--event .events {
    background-color: $color-white;
    padding-top: 0;
}
