// @name Breadcrumb
.breadcrumb {
    $breadcrumb__font-size: 1.4;
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    width: 70%;
    p {
        font-size: #{$breadcrumb__font-size}em;
        font-weight: $normal;
        color: $color-2--0;;
        .breadcrumb__here {
            color: $color-3--3;
        }
        a {
            font-weight: $normal;
            color: $color-white;
            @include default-icons-after('\e017', 0 0 0 5px, em(1.2, $breadcrumb__font-size), $color-2--0, em(-.2, 1.2));
            &:last-child {
                &:after {
                    color: $color-2--0;
                }
            }
            &:hover {
                text-decoration: none;
                span {
                    text-decoration: underline;
                }
            }
        }
    }
}
