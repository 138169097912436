.bloc-link {
    .bloc-link__item {
        .list-content-2 {
            font-weight: $light;
            color: $color-black;
            margin-right: 1.0625em;
            > li > a:focus, a:hover {
                color: $color-black;
            }
        }
    }
}
