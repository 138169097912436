.carrousel2 {
    .listItems {
        display: flex;
        justify-content: space-between;
        margin-left: 15px;
    }
    .item {
        text-align: center;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }
    .cycle-carousel-wrap {
        text-align: center;
        width: 100%;
    }
    .item__wrap {
        display: block;
        width: 100%;
        min-height: 230px;
        transition: background ease 0.3s, fill ease 0.3s;
        &:hover, &:focus {
            .wrapper-img {
                background-color: $color-1--3;
                  transition: background ease 0.5s;
                svg {
                    fill: $color-2--0;
                    transition: fill ease 0.5s;
                }
            }
        }
    }
    .prevCaroussel2 {
        button {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            left: -14px;
            height: 130px;
            width: 20px;
        }
    }
    .nextCaroussel2 {
        button {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            right: -24px;
            height: 130px;
            width: 20px;
        }
    }
}

// en un clic
.blocUnClic {
    background-color: $color-3--1;
    padding-top: 7em;
    padding-bottom: 6em;
    .blocUnClic__wrapper {
        position: relative;
    }
    .title-1 {
        &:before {
            background: url("../Images/bg/bg-border-3.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-3.png") 100% 52% repeat-x;
        }
    }
    .wrapImg {
        background: url("../Images/bg/bg-border-1.png");
        height: 160px;
        width: 160px;
        border-radius: 50%;
    }
    .wrapper-img {
        background-color: $color-2--0;
        border-radius: 50%;
        height: 140px;
        width: 140px;
        top: 50%;
        transform: translateY(-50%) translateX(7%);
        position: relative;

        .svg-icon {
            max-height: 50px;
            width: 100%;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .title {
        padding-top: 30px;
        text-transform: uppercase;
        font-weight: $bold;
        color: $color-black;
        font-size: 1.4em;
        white-space: normal;
    }
}

