.list-type-1--news{
    .category {
        font-size: 1.2em;
        color: $color-black;
        background-color: $color-2--0;
        display: inline-block;
        padding: 8px 10px;
        position: relative;
        a {
            color: $color-black;
        }
    }
}
