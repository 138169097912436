// import google font
@if variable-exists(import-google-font-1) {
    @import url($import-google-font-1);
}

@if variable-exists(import-google-font-2) {
    @import url($import-google-font-2);
}

@if variable-exists(import-google-font-3) {
    @import url($import-google-font-3);
}

// Icon fonts
// create font-face for icon fonts
@include fontFace(icons-default, 'default-icons-webfont', normal, normal);
@include fontFace(icons-project, 'icons', normal, normal);

// @name Body
body {
    font-family: $typo-1;
    font-weight: $normal;
    overflow-x: hidden;
}

// hide SVG symbol
.svg-symbol {
    display: none;
}
