// # Global
$domain: "saint-aignan-grandlieu.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:100,300,300i,400,700";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700";
$import-google-font-3: "https://fonts.googleapis.com/css?family=Roboto:300,400,700'";

$typo-1: 'Roboto', sans-serif;
$typo-2: 'Roboto Condensed', sans-serif;
$typo-3: 'Playfair Display', serif;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #d80000;
$color-green: #278417;
$tarteaucitron-green: #1b870b;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #7ec8cd;
$color-1--2: #00a3b2;
$color-1--3: #006f84;
$color-1--4: #006d9b;
$color-1--5: #086477;
$color-1--6: #03505e;

// ## color-2
$color-2--0: #c7e048;
$color-2--1: #e2eb61;
$color-2--2: #85bb3c;
$color-2--3: #00863a;
$color-2--4: #c2dd9d;
$color-2--5: #71b33c;

// ## Color-3
$color-3--0: #d4d4d4;
$color-3--1: #f6f6f6;
$color-3--2: #c5c5c5;
$color-3--3: #6f7680;
$color-3--4: #282828;
$color-3--5: #afafaf;
$color-3--6: #d4d6d9;
$color-3--7: #313131;
