.slider-home__wrapper {
    &--active {
        .search-box, .menu-home, .slider__content {
            opacity: 1 !important;
            visibility: visible !important;
        }
    }
}
.slider {
    //min-height: inherit;
    margin-bottom: 6em;
    .listItems {
        //min-height: inherit;
    }
    .item {
        .slider__picture-wrapper {
            position: relative;
            &:before {
                content: '';
                padding-top: 39.75%;
                display: block;
            }
        }
        &:before {
            content: inherit;
            display: none;
        }
    }
    .slider__picture {
        .slider__video-button {
            width: 40px;
            height: 40px;
            &:before {
                font-size: 20px;
            }
        }
    }
    .slider__content {
        top: unset;
        width: 100%;
        text-align: center;
        display: block;
        position: relative;
        background: $color-1--3;
        content: "";
        min-height: 100px;
    }

    .category {
        font-size: 1.4em;
        top: -19px;
        padding: 7px 16px;
    }
    .title {
        font-size: 3em;
        text-shadow: none;
        color: $color-white;
        background-color: $color-1--3;
        width: 100%;
        margin-top: -32px;
        &:after {
            right: 10%;
        }
    }
    .nextCarrousel1 {
        right: 2%;
        top: inherit;
        bottom: 0;
        transform: none;
    }
    .prevCarrousel1 {
        left: 2%;
        top: inherit;
        bottom: 0;
        transform: none;
    }
    .nextCarrousel1, .prevCarrousel1 {
        button {
            height: 100px;
            width: 20px;
        }
    }

}
