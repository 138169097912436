// @name Search box
// @description Search module
.search-box {
    float: right;
    padding: 0;

    form {
        margin: 0;
    }
    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.8em;
    }
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: inline-block;
        vertical-align: middle;
        padding-left: 50px;
    }
    input {
        border: none;
        border-bottom: 5px solid #e2e4e6;
    }
    label {
        font-family: $typo-2;
        font-weight: bold;
        color: $color-1--3;
    }
    .button-1--svg {
        padding: 1.22857em 1.22857em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
    }
    .search-box__button-wrapper {
        .button-1--svg {
            left: 50%;
        }
    }
    .text-help {
        position: absolute;
        transition: .1s top;
        top: 43%;
        transform: translateY(-43%);
        left: 150px;
        z-index: 1;
        font-style: italic;
        font-family: $typo-2;
        font-size: 1.4em;
    }
    .input-text:focus + .text-help, .text-help:focus {
        top: 2px;
        transition: .2s top;
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1em;
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
            input {
                width: 370px;
                z-index: 3;
                position: relative;
                background: $color-white;
                font-style: italic;
            }
        }
    }

    // Drop down menu
    .ddm {
        position: static;
        .button__background {
            background: url('../Images/bg/bg-border-1.png');
            height: 70px;
            width: 70px;
            border-radius: 50%;
            position: relative;
        }
        .ddm__sub-level {
            top: 35px;
            right: 0;
            width: 65%;
            height: 80px;
            background: $color-white;
            padding-top: 10px;
            transform: scaleX(0);
            transition: transform 3s;
        }
    }
    .ddm--active {
        .ddm__sub-level {
            transform: scaleX(1);
            transition: all 3s;
            top: 0;
            width: 63%;
        }
    }
}

// @name Search list
.pertinence {
    font-size: 1.2em;
    span {
        font-size: em(1, 1.2);
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .pertinence span {
        font-size: 1.1em;
        font-weight: $bold;
    }
}

meter {
    -webkit-appearance: meter;
    -moz-appearance: meterbar;
    width: em(6, 1.2);
    height: em(1, 1.2);
    border: 1px solid darken($color-3--1, 20%);
    &::-webkit-meter-bar {
        background: darken($color-3--1, 20%);
        height: 100%;
        width: 100%;
    }
    &::-webkit-meter-optimum-value {
        height: 100%;
        background: $color-1--2;
    }
    &:-moz-meter-optimum {
        background: darken($color-3--1, 20%);
        border: none;
    }
    &:-moz-meter-optimum::-moz-meter-bar {
        background: $color-1--2;
    }
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background: antiquewhite;
    }
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background: steelblue;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, meter {
        display: none;
    }
}

.secondaryContentSection {
    padding-right: 2.5rem;
    a {
        font-size: 1.4rem;
        color: $color-black;
    }
    h3 {
        margin: 1rem 0;
        color: $color-black;
        font-size: 20px;
        font-weight: 400;
    }
    .facet-label,
    .subtitle {
        margin: 1rem 0;
        padding: 1rem 0.7rem;
        color: $color-white;
        background-color: $color-1--3!important;
        font-size: 16px;
        text-transform: capitalize;
    }
    .facet-option-list {
        margin-left: 0;
        li {
            &:before {
                content: none;
            }
        }
    }
    .column-bloc {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .solr-field {
        label {
            font-size: 1.4rem;
            width: 10%;
            margin-right: 3%;
            display: inline-block;
        }
        input {
            font-size: 1.4rem;
        }
        .form__field-datewrapper {
            display: inline-block;
            width: 80%;
        }
    }
    .button-1 {
        display: table;
        margin-top: 1.5rem;
        padding: 1.4rem 3.8rem;
        font-size: 1.2rem;
        margin-right: 0;
        margin-left: auto;
        &:before {
            content: '\e057';
        }
    }
}
