.services-box {
    width: 100%;
    top: 10px;
    position: relative;
    .ddm {
        &.services-box__wrapper {
            position: relative;
            right: unset;
            top: unset;
            z-index: unset;
            .ddm__sub-level {
                left: 0;
                position: absolute;
                z-index: 3;
                width: 100%;
            }
            .ddm__wrapper {
                background: none;
                padding: 0;
            }
            &:hover {
                .ddm__button {
                    width: 100%;
                }
            }
        }
        .ddm__title {
            span {
                font-size: 1.2em;
                position: relative;
                display: inline-block;
                left: 100px;
                &:before {
                    top: 0;
                    left: -30px;
                }
            }
        }
        .button__background {
            border-radius: 0;
            width: 100%;
            .svg-icon {
                display: inline-block;
                left: 0;
                top: 0;
            }
        }
        .ddm__button {
            border-radius: 0;
            width: 100%;
        }
    }

}

//slide-bar
.slide-bar {
    width: 100%;
    top: 20px;
    position: relative;
    z-index: 2;
    //transform: translate(0, 0);
    transform: none;
    .ddm__title {
        width: 100%;
        span {
            font-size: 1.2em;
            color: $color-1--3;
            top: 50%;
        }
        svg {
            fill: $color-black;
            stroke: $color-black;
        }
        .svg-icon {
            position: relative;
            left: -96px;
            top: 50%;
        }
    }
    .ddm {
        .button__background {
            border-radius: 0;
            width: 100%;
            top: 0;
            right: 0;
            height: 125px;
        }
        .ddm__button {
            border-radius: 0;
            width: 100%;
            background: $color-white;
            height: 100px;
        }
        &.slide-bar__wrapper {
            .ddm__sub-level {
                left: 0;
                transform: translate(0, 0);
                top: 123px;

            }
            &.ddm--active {
                transform: none;
                .ddm__sub-level {
                    transform: translate(0, 0);
                    top: 123px;
                    width: 100%;
                    z-index: 2;
                }
                .button__background {
                    right: unset;
                    transform: none;
                }
            }

        }
    }
}
