// @name site informations
.site-infos {
    .site-infos__img-wrap {
        float: left;
        margin-right: 2em;
    }

    .site-infos__wrapper {
        overflow: hidden;
    }

    p {
        font-size: 1.3em;
        margin: em(.5, 1.3) 0 em(.8, 1.3);

        &:first-child {
            margin-top: 0;
        }
    }

    .site-infos__address {
        text-transform: uppercase;

        .title {
            font-size: 1.3em;
            font-weight: $bold;
        }
    }

    .hour-place {
        font-size: 1.4em;
        text-align: left;
        font-style: italic;
        color: $color-white;

        .hour {
            font-size: 1em;
        }
    }
}

//site infos contact
.site-infos-contact {
    //position: relative;

    .site-infos-contact__wrapper {
        text-align: center;
    }

    .list-infos__item {
        font-size: 1.6em;
        padding-bottom: 10px;

        &:before {
            color: $color-2--0;
            left: 123px;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    a {
        color: $color-white;
        font-weight: $light;
    }

    .button-2 {
        color: $color-2--0;
        font-weight: $bold;
        font-size: 1.5em;
        display: inline-block;
        line-height: 1em;
        margin-left: 80px;

        &:before {
            content: "\e02a";
            color: $color-2--0;
        }

        &:hover {
            color: $color-white;

            &:before {
                color: $color-white;
            }
        }
    }

    &__social-networks {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .button-1 {
        background-color: $color-1--3;
        transition: background ease 0.3s, color ease 0.3s;
        margin: 0 0.3rem;

        &:before {
            font-size: 1.5em;
        }

        &.-facebook {
            &::before {
                content: "\e099";
            }
        }

        &.-instagram {
            &::before {
                content: "\e0a1";
            }
        }

        &.button-1--no-text {
            padding: 1.4em 1.75em;
        }

        &:hover {
            background-color: $color-2--0;
            transition: background ease 0.5s;

            &:before {
                color: $color-3--7;
                transition: color ease 0.5s;
            }
        }
    }
}
