// @name Header page
.header {
   // @extend .wrapper-main;
    @extend .clear-fix;
    padding-bottom: 1em;
    padding-top: 5px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

// @name Top of content
.top-of-content {
    background: $color-1--3;

}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding-top: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid $color-3--2;
}
.page__wrapper {
    display: flex;
    flex-wrap: wrap;
}
.header {
    flex-basis: 100%;
    order: 2;
}
.slider-home__wrapper {
    order: 1;
    flex-basis: 100%;
}
// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {

}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 7em;
    padding-bottom: 3em;
}

.section-main__wrapper-2 {
    display: table;
    width: 100%;
    > * {
        display: table-cell;
        vertical-align: top;
    }
}

// @name Main section content
.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background: $color-3--7;
    color: $color-white;
    .footer__wrapper {
        @extend .wrapper-main;
        padding-top: 3em;
        padding-bottom: 3em;
    }
    .footer__wrapper-2 {
        margin: 0 -1.5em;
        display: flex;
        width: 100%;
        > * {
            flex: 1 1 31.3333%;
            margin: 0 1%;
        }
    }
}
