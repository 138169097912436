// @name Input radio and checkbox
// @description
// @state .column-2 - Indicates the column positioning
.radio, .checkbox {
      // Checkbox Style 1
    &.checkbox-style-1 {
        label {
            font-size: 1.3em;
            .text {
                padding: .3rem .5rem;
            }
            .number-of {
                margin-left: .5rem;
                padding: .5rem 0;
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
