.homePublications {
    margin-top: 6em;
    margin-bottom: 6em;
    .wrapper-main {
        padding: 0 0;
    }
    .title-1 {
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
    }
    .homePublications__wrapper {
        position: relative;
    }
    .publications__wrap {
        display: table;
        table-layout: fixed;
        width: 100%;
        > * {
            display: table-cell;
        }
        .bloc-publications__item {
            width: 70%;
            padding-right: 5rem;
        }
    }
    .list-type-2__picture--2 {
        min-width: 195px;
        height: auto;
    }
    .bloc-publications__wrapper {
        padding-left: 4em;
        min-width: 430px;
    }
    .bloc-publications__title  {
        .title {
            font-size: 2em;
        }
        a {
            font-size: 1.2em;
        }
        &:before {
            width: 177px;
            left: 0;
            top: 50px;
        }
    }
    .publications__description {
        font-size: 1.5em;
        font-weight: $light;
        font-family: $typo-1;
    }
    .list-document-1__listitems {
        display: table-cell;
        vertical-align: top;
        li:before {
            background-color: transparent;
            font-size: 2.3em;
            position: relative;
            z-index: 2;
            left: 49%;
            top: 30px;
            line-height: unset;
            transform: translateX(-50%);
        }
        .list-document-1__item--read .list-document-1__item--wrap {
            background-color: $color-1--2;
            &:hover, &:focus {
                background-color: $color-1--3;
                transition: background-color ease 0.5s;
            }
        }
        .list-document-1__item--wrap {
            position: absolute;
            height: 12rem;
            width: 12rem;
            background-color: $color-1--3;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            transition: background-color ease 0.3s;
            a {
                color: $color-white;
                bottom: 25px;
                transform: translateX(-50%);
                left: 50%;
                position: absolute;
                text-align: center;
            }
            &:hover, &:focus {
                background-color: $color-1--2;
                transition: background-color ease 0.5s;
                a {
                    text-decoration: underline;
                }
            }
        }
        .list-document-1__item {
            background: url("../Images/bg/bg-border-1.png");
            height: 14rem;
            width: 14rem;
            border-radius: 50%;
            display: block;
            position: relative;
            right: 0;
            top: 0;
        }
        .list-document-1__file-size {
            padding-left: 0;
        }
    }
    .newsletter {
        display: table-cell;
        background-color: $color-2--2;
        width: 315px;
        width: 30%;
        position: relative;
        .newsletter__title {
            position: relative;
            font-weight: $light;
            text-transform: uppercase;
            font-size: 1.8em;
            padding-left: 30px;
            padding-bottom: 20px;
            width: 190px;
            top: 10px;
            span {
                font-size: 1.4em;
            }
        }
        .newsletter__picture {
            max-width: 100%;
            height: auto;
            position: absolute;
            top: 40px;
            right: 6px;
        }
        .newsletter__wrapper {
            padding-left: 30px;
        }
        .newsletter--label {
            font-size: 1em;
            font-weight: $light;
        }
        .newsletter__fields-wrapper {
            width: 90%;
            padding-bottom: 10px;
        }
        label {
            margin: 0;
        }
        input {
            background-color: $color-2--5;
            border: none;
        }
        .text-help {
            color: $color-black;
        }
        .button-2 {
            background-color: transparent;
            color: $color-black;
            font-size: 1.6em;
            padding-left: 2.5em;
            &:before {
                background-color: $color-1--3;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                padding-top: 9px;
                left: 0;
                font-size: 0.7em;
                color: $color-2--2;
            }
            &:hover, &:focus {
                text-decoration: underline;
                &:before {
                    background-color: $color-2--2;
                    color: $color-black;
                }
            }
        }
        .list-content-1 {
            li {
                display: inline-block;
                padding-right: 25px;
                font-weight: $normal;
                font-size: 1.2em;
                &:before {
                    color: $color-1--3;
                }
                a:hover {
                    color: $color-black;
                }
            }
        }
    }
    .link-view-2 {
        position: absolute;
        right: 0;
        top: -69px;
        .button-1 {
            background-color: $color-2--2;
            &:hover {
                background-color: $color-1--2;
            }
            &.button-1--no-text {
                background-color: $color-1--2;
                &:hover {
                    background-color: $color-2--2;
                }
            }
        }
    }
}
