.iam-box {
    top: unset;
    right: unset;
    height: 100px;
    background: white;
    position: relative;
    border-bottom: 1px solid black;
    .ddm {
        &.iam-box__wrapper {
            .ddm__sub-level {
                z-index: 1002;
                left: 0;
                width: 100%;
            }
            .ddm__wrapper{
                width: 100%;
                &:after {
                    content: none;
                }

            }
        }
    }
    .iam__listitems {
        position: relative;
        left: 40%;
    }
    .button-1.button-1--big {
        margin-top: 30px;
        left: 40%;
    }
}
