.reports {
    .title-1 {
        &:before {
            flex: unset;
            min-width: 35%;
        }
        &:after {
            flex: unset;
            min-width: 15%;
        }
    }
    .reports__listitem {
        flex: unset;
    }
    .reports__wrap {
        display: table;
        border-collapse: separate;
        border-spacing: 40px 0;
        width: 100%;
    }
    .report__item {
        flex-basis: unset;

        a {
            display: block;
            width: 100%;
            overflow: visible;
            position: relative;
            color: $color-white;
            &:hover, &:focus {
                .item__content {
                    top: unset;
                }
            }
        }
        img {
            width: 95%;
        }
    }
    .items--small {
        display: table-cell;
    }
    .reports__items--small {
        flex-basis: unset;
        min-width: unset;
        flex-wrap: unset;
        display: block;
        img {
            width: 95%;
            height: auto;
        }
    }
    .item__content {
        h3 {
            font-size: 1.6em;
            margin-top: 35px;
        }
        .item__content--description {
            position: relative;
            top: 26%;
            width: 100%;
            padding: 0 10px;
            color: $color-white;
            box-sizing: border-box;

        }
        .button-1 {
            font-size: 1.3em;
            margin-top: 5px;
            &:before {
                left: 13px;
            }
        }
        text-align: center;
        position: relative;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        min-height: initial;
        background: rgba(0, 163, 178, 0);
        &.item__content--small {
            width: 100%;
            min-height: initial;
            > .item__content--description {
                h3 {
                    font-size: 2.1em;
                    margin-top: 35px;
                }
                p {
                    font-size: 1.3em;
                }
                .button-1 {
                    font-size: 1.7em;
                    &:before {
                        height: 30px;
                        width: 30px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }
    .video {
        &:after {
            top: 35%;
            left: 48%;
        }
    }
}
