// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications__picture {
    display: block;
    float: left;
    img {
     //   margin-right: 2em;
    }
}

.bloc-publications__wrapper {
    overflow: hidden;
    display: table-cell;
    padding-left: 17px;
}

.bloc-publications__title {
    $bloc-publications__title__font-size: 1.8;
    font-size: #{$bloc-publications__title__font-size}em;
    font-family: $typo-3;
    font-weight: $bold;
    margin: 0 0 em(.8, $bloc-publications__title__font-size);
    color: $color-black;
    text-transform: uppercase;
    position: relative;
    .title {
        font-family: $typo-1;
        font-weight: $bold;
    }
    .numero {
        font-size: 2em;
        font-family: $typo-3;
    }
    &:before {
        content: '';
        height: 1px;
        width: 55px;
        position: absolute;
        top: 25px;
        left: 0;
        background-color: $color-3--0;
    }
    a {
        font-family: $typo-1;
        font-weight: $thin;
        color: $color-2--3;
        display: block;
        padding-top: 10px;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}
