//events on content page
.section-main {
    .events {
        background-color: $color-3--1;
        padding-top: 5em;
        margin-bottom: 0;
        padding-bottom: 6em;
    }
}

// events on home page
.events {
    background-color: $color-white;
    padding-top: 4em;
    margin-bottom: 7em;
    .title-1 {
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        span {
            flex: 1 1 content;
            display: block;
        }
    }
    .events__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        margin: 0 -5%;
    }
    .bloc-event {
        flex: 1 1 25.3333%;
        margin: 0 4%;
    }
    .bloc-event__picture {
        img {
            width: 100%;
        }
    }
    .bloc-event__wrapper {
        max-width: 100%;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        .bloc-event__wrapper-date {
            padding-top: 5em;
        }
    }
    .bloc-event__picture + .bloc-event__wrapper  {
        top: inherit;
        transform: none;
        position: relative;
        .bloc-event__wrapper-date {
            padding-top: 0;
        }
    }
    .date-1 time {
        margin: 0 -0.16em;
    }
    .link-view-2 {
        position: absolute;
        right: 50px;
        top: -72px;
    }
}
