/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer {
    overflow: hidden;
    position: relative;
}

.jspPane {
    position: absolute;
}

.jspVerticalBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    background: red;
}

.jspHorizontalBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16px;
    background: red;
}

.jspCap {
    display: none;
}

.jspHorizontalBar .jspCap {
    float: left;
}

.jspTrack {
    background: #03505e;
    position: relative;
}

.jspDrag {
    background: $color-1--3;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;


    &::before {
        content: '\e023';
        font-family: 'icons-default';
        color: $color-black;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1rem;
        height: 1rem;
        line-height: .8rem;
        font-size: 1rem;
        font-weight: $light;
        margin-top: -.05rem;
        margin-left: -.5rem;
        border-top: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
    }
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
    float: left;
    height: 100%;
}

.jspArrow {
    background: #03505e;
    text-indent: -20000px;
    display: block;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.jspArrow.jspDisabled {
    cursor: default;
    background: #03505e;
}

.jspVerticalBar .jspArrow {
    height: 16px;
}

.jspHorizontalBar .jspArrow {
    width: 20px;
    float: left;
    height: 100%;
}

.jspVerticalBar .jspArrow:focus {
    outline: none;
}

.jspArrow {
    position: relative;

    &.jspArrowUp::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-bottom: 4px solid $color-2--0;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
    }

    &.jspArrowDown::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 4px solid $color-2--0;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
    }
}

.jspCorner {
    background: #eeeef4;
    float: left;
    height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner {
    margin: 0 -3px 0 0;
}
