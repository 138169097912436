// @name Signature stratis
.stratis {
    background: transparent;
    width: 100%;
    font-size: 1.2em;
    font-weight: $light;
    color: $color-white;
    text-align: right;
    text-transform: uppercase;
    span {
        display: none;
    }
    a {
        display: inline-block;
        padding: 4px 5px;
        color: #ffffff;
        background: $color-red;
        text-decoration: none;
        margin-left: 5px;
        &:hover, &:focus {
            color: $color-red;
            background: #ffffff;
        }
    }
}
