// @name Shame
// @description Because no project is perfect, you can add some code here if you don't know where to add it.

.blocUnClic .wrapper-img .svg-icon{
  width: auto;
  position: absolute;
}

.feEditOn .outlineFeEdit:hover, .feEditOn.showFeEdit .outlineFeEdit {
  background: none;
  background: rgba(0,0,0,0.2) !important;
}
.column-bloc .list-document-1__item--wrap {
  display: inline;
}
.filter_big {
  width : 100%;
}
.caption, .date{
  color : #000;
}

