.fancybox-wrap {
    background: transparent;
}

.fancybox-galerie {
    padding: 0;
}

.fancybox-opened .fancybox-title {
    padding: 10px;
}

.fancybox-wrap .fancybox-close {
    right: 0;
    bottom: 0;
    padding: 0;
    background: none;
    border: 1px solid transparent;
    &:hover, &:focus {
        border: 1px dotted #fff;
    }
    span {
        display: none;
    }
    img {
        display: inline;
    }
}

.fancybox-nav {
    bottom: 55px;
    width: 35px;
    height: 75%;
    display: block;
    &:focus {
        border: 1px dotted #fff;
    }
    span {
        display: none;
    }
    img {
        display: inline;
    }
}

.fancybox-prev {
    left: 0;
}

.fancybox-next {
    right: 0;
}

// FANCYBOX GALLERY
.fancybox-more-infos {
    display: block;
}

#fancybox-buttons {
    width: 34px;
    height: 32px;
    overflow: hidden;
    position: fixed;
    top: inherit;
    bottom: 0;
    z-index: 9996;
    .btnPlay {
        color: #000;
        padding-left: 0;
        text-align: center;
        border: 1px solid transparent;
        height: 30px;
        width: 32px;
        img {
            display: block;
        }
        &:hover, &:focus {
            border: 1px dotted #fff;
        }
        &:before {
            background: none;
        }
        &.btnPlayOn {
            &:before {
                background: none;
            }
        }
    }
}

.fancybox-overlay {
    background: #000000;
}

.fancybox-close-wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 32px;
    background: #000;
    background: rgba(0, 0, 0, .8);
    text-align: right;
    z-index: 9995;
    .fancybox-close {
        position: static;
        bottom: inherit;
        right: inherit;
    }
}

.fancybox-galerie .fancybox-wrap-title {
    position: fixed;
    bottom: 32px;
    left: 0;
    width: 100%;
    z-index: 9996;
}

.fancybox-galerie .fancybox-title {
    position: absolute;
    bottom: -9999px;
    left: 0;
}

.fancybox-title {
    display: none;
}

.display .fancybox-title {
    display: block;
    bottom: 0;
    z-index: 9800;
    background: rgba(0, 0, 0, 0.6);
}

.fancybox-galerie .fancybox-more-infos {
    position: fixed;
    bottom: 0;
    left: 32px;
    z-index: 9996;
    display: block;
    border: 1px solid transparent;
    &:hover, &:focus {
        border: 1px dotted #fff;
    }
}
