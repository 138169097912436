.cartographie {
    &__wrapper {
        flex-direction: column;
    }
    &__nav {
        width: 100%;
        min-width: 100%;
        margin-bottom: 3rem;
        ul {
            font-size: 1.3rem;
            li {
                a {
                    .wrap-icon {
                        width: 3.7rem;
                        min-width: 3.7rem;
                        margin-right: 2rem;
                        svg {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
    &__picture {
        picture {
            img {
                display: block;
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        &-link {
            width: 11rem;
            height: 11rem;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $color-3--0;
            img {
                display: block;
                margin: 0.5rem auto 0;
            }
        }
    }
}
