.site-infos-contact {
    min-width: 200px;

    .site-infos-contact__wrapper {
        padding-left: 0;
        text-align: left;
    }

    .site-infos-contact__social-networks {
        justify-content: flex-start;
    }

    .list-infos__item {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .list-infos__item:before {
        top: 20px;
        left: 0;
    }

    .button-2 {
        margin-left: 0;
    }
}

.site-infos {
    flex: unset;

    .hour-place {
        margin-top: 15px;
    }
}

.menu-cross {
    ul {
        padding-left: 4em;
    }

    li {
        width: 100%;
    }
}



