.home .news {
    margin-left: 0;
    >.news__wrap {
        margin-left: 0;
    }
}

.news {
    margin-left: 0;
    .wrapper-main {
        padding: 0 1em;
    }
    .title-1 {
        margin-left: -40px;
            &:before {
                flex: unset;
                min-width: 35%;
            }
            &:after {
                flex: unset;
                min-width: 20%;
            }
    }
    .news__wrapper {
        text-align: center;
        width: 100%;
        display: block;
    }
    .bloc-news__item {
        display: inline-block;
    }
    .bloc-news__title {
        width: 225px;
    }
    .column-bloc__wrapper {
        text-align: left;
    }
    .link-view-2 {
        right: 0;
    }
}
