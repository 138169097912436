.carrousel2 {
    .listItems {
        margin-left: 0;
    }
    .prevCaroussel2 button {
        left: -3px;
    }
    .nextCaroussel2 button {
        right: -9px;
    }
}

.blocUnClic {
        .wrapImg {
            margin-left: 23px;
            height: 150px;
            width: 150px;
        }
    .wrapper-img {
        height: 130px;
        width: 130px;
    }
}


