// @name Dropdown menu
.ddm.ddm--on .filters__ddm {
    display: inline-block;
    vertical-align: middle;
    .button-2 {
        &:before {
            content: "\e031";
        }
    }
}

.filters > .ddm > .ddm__sub-level {
    position: static;
    top: inherit;
    right: inherit;
    z-index: inherit;
}

// @name Filters
// @description Filters above list. The Fields are managed with flexbox.
// You can display the fields on 1, 2, 3 or 4 columns.
// On the div element .filters__fields add a good class:
// 1 column: no class, it's the default display.
// 2 columns: add class .filters__fields--column-2
// 3 columns: add class .filters__fields--column-3
// 4 columns: add class .filters__fields--column-4
// By default, label and field are place on top of other. For positionning side by side add the class .filters__wrapper--in-line on .filters__wrapper

.filters {
    padding: 0.1em 0 3em 0;
    background: $color-1--3;
    * {
        &:hover, &:focus {
            outline-color: $color-white !important;
        }
    }
    .button-2 {
        padding: 14px 20px;
        padding-left: 3rem;

        &.button-2--no-text::before {
            left: 50%;
        }

        &:before {
            left:1rem;
        }
    }
    // adapt form
    form {
        margin: 2em 0 0;
    }
    fieldset {
        margin: 0;
    }
    input, select {
        border-color: $color-white;
    }
    label, .label {
        color: $color-white;
    }
    .label {
        cursor: default;
    }
    .radio, .checkbox {
        &.column-2 {
            legend + div {
                margin-top: 1.1em;
            }
        }
    }
    &.filters--event, &.filters--directory {
        margin-top: -10rem;
        .filters__header {
            text-align: right;
        }
    }
}

.filters__header {
    text-align: left;
}

.filters__album-types {
    .radio.checkbox-style-1 > div, .checkbox.checkbox-style-1 > div {
        margin-bottom: 0;
    }
}


.form--3 {
    .radio, .checkbox {
        &.radio-checkbox--inline {
            legend {
                margin-bottom: em(.5, $radio-checkbox__legend__font-size);
            }
        }
    }
    legend, .legend {
        color: $color-white;
    }
    .legend {
        margin-top: 2rem;
    }
    .filters__event, .filters__locate {
        margin: 0 0 0 1.5rem;
    }
    .filters__event {
        color: $color-black;
        &:before {
            color: $color-black;
            content: "\e009";
        }
    }

    .filters__locate {
        color: $color-2--4;
        &:before {
            color: $color-white;
            font-family: icons-project;
            content: "\e00e";
        }
    }
}


.ddm.ddm__facet {
    &.ddm--off {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        > .ddm__title {
            display: none;
        }
    }
    > .ddm__title {
        > button {
            text-align: left;
            display: block;
            font-size: #{$input__font-size}em;
            font-family: $typo-1;
            background: $color-white;
            width: 100%;
            max-width: 495px;
            font-weight: $light;
            border: 1px solid $color-white;
            padding: em(.95, $input__font-size) em(1, $input__font-size);
            color: $color-black;
            background: $color-white url("../Images/form/select_right.png") no-repeat 99% 50%;
            padding-right: em(3, $input__font-size);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .ddm__sub-level {
        position: absolute;
        top: 38px;
        right: 0;
        left: 0;
        z-index: 100;
        display: none;
        background: $color-white;
        border: 1px solid $color-black;
        max-height: 150px;
        overflow-y: auto;
    }
    &.ddm--active {
        .ddm__sub-level {
            display: block;
        }
    }
}

.ddm__more-filters {
    .ddm__title {
        text-align: right;
    }
    .ddm__button {
        color: $color-white;
        font-size: 1.4em;
        padding-left: 2rem;
        @include default-icons-absolute-before('\e014', 1.3rem, $color-white, 50%, inherit, inherit, 0);
        &:before {
            transform: translateY(-50%);
        }
    }
    &.ddm--active {
        .ddm__button {
            &:before {
                content: "\e015";
            }
        }
    }
}



.filters__wrapper {
    display: table;
    width: 100%;
    // A supprimer ?
    /*
    .filters__field-1, .filters__field-2 {
      display: inline;
      vertical-align: middle;
    }
    */
    .filters__field-2 {

    }
    &.filters__wrapper--in-line {
        .filters__field-wrapper {
            width: 100%;
            .filters__field-1, .filters__field-2 {
                display: table-cell;
            }
            .filters__field-1 {
                text-align: right;
                padding-right: 1em;
                white-space: nowrap;
            }
            .filters__field-2 {
                display: table-cell;
                width: 100%;
            }
        }
        .filters__button-wrapper {
            padding-top: .5em;
        }
    }
}

// Just for ie11
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .filters__wrapper {
        table-layout: fixed;
    }
}

.filters__fields, .filters__button-wrapper {
    display: table-cell;
    vertical-align: top;
}

// @name Container of any fields
// @state .filters__fields--column-2 - Indicates 2 columns
// @state .filters__fields--column-3 - Indicates 3 columns
// @state .filters__fields--column-4 - Indicates 4 columns
// @state .filters__fields--in-line - Indicates label and field are side by side. Not works with checkbox and radio.
.filters__fields {
    width: 100%;
    .filters__fields-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 0;
        margin-left: -1%;
        margin-right: -1%;
        > .filters__field-wrapper {
            align-self: flex-end;
            margin: .5em 1% 1.5em;
            flex: 0 0 100%;
        }
    }

    .filters__fields--column-2 {
        > .filters__field-wrapper {
            flex: 0 0 48%;
            width: 48%;
        }
    }
    .filters__fields--column-3 {
        > .filters__field-wrapper {
            flex: 0 0 31.3333%;
            width: 31.3333%;
        }
    }
    .filters__fields--column-4 {
        > .filters__field-wrapper {
            flex: 0 0 23%;
            width: 23%;
        }
    }
}

.filters__button-wrapper {
    padding-left: 2em;
    padding-top: 3.3em;
    white-space: nowrap;
}

// Just for ie11
@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .filters__button-wrapper {
        width: 25em;
    }
}

.filters__reset, .filters__reset-2 {
    &:before {
        content: "\e058";
    }
}

.filters__submit {
    &:before {
        content: "\e031";
    }
}

// @name Filters dark
// @description Filters with dark backhround
.filters__dark {
    background: $color-3--3;
}


//event filter {
.filters--event {
    margin-bottom: 2em;
    .list__rss {
        position: relative;
        top: 0;
        right: 0;
        margin-left: 10px;
    }
    .filters__event {
        &:hover {
            background: $color-2--2;
        }
    }
}
