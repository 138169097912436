.header {
    margin-bottom: 0;
    border: 0;
}

// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
}

.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}
.section-main__aside {
    margin-top: 30px;
}
// @name Footer
.footer {
    .footer__wrapper-2 {
        margin: 0;
        display: block;
        > * {
            flex: inherit;
            margin: 0 0 2em 0;
        }
    }
}

