.filters {
    form {
        margin: 2em 0 0;
    }
    &.filters--event, &.filters--directory {
        margin-top: 0;
    }
}

.ddm {
    .filters__ddm {

    }
    .filters__ddm__sub-level {
        display: block;
        height: auto;
        //overflow: hidden;
        max-height: 0;
        transition: max-height ease .2s;
    }
    &.ddm--active {
        .filters__ddm__sub-level {
            max-height: 100em;
            transition: max-height ease 1s;
        }
    }
}

.form--3 {
    .filters__event, .filters__locate {
        margin: 0;
    }
}

.filters__wrapper {
    display: block;
    table-layout: inherit;
}

.filters__fields, .filters__button-wrapper {
    display: block;
    vertical-align: inherit;
}

.filters__fields {
    .filters__fields-wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        margin: 0;
        > .filters__field-wrapper {
            align-self: inherit;
            flex: inherit;
            margin-right: 0;
            margin-left: 0;
        }
    }
    .filters__fields--column-2, .filters__fields--column-3, .filters__fields--column-4 {
        > .filters__field-wrapper {
            width: 100%;
        }
    }
}

.filters__button-wrapper {
    padding-left: 0;
    padding-top: 0;
    text-align: right;
    width: 100%;
}

.filters__keywords {
    .grid {
        display: flex;
        flex-wrap: nowrap;
        > *:first-child {
            width: 100%;
        }
    }
}

.ddm.ddm__more-filters {
    > .ddm__sub-level {
        position: static;
    }
}

.filter__dates {
    .grid {
        display: flex;
        flex-wrap: wrap;
        > * {
            width: 100%;
        }
        > *:nth-child(2) {
            width: calc(100% - 40px);
        }
        > *:last-child {
            width: 40px;
        }
    }
    .form__field-datewrapper {
        margin: .5em 0 1.5em;
    }
}
