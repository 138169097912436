// @name List infos
.list-infos {
    font-family: $typo-2;
}

.list-infos__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    > * {
        flex: 1 1 0;
    }
    .list-infos__listitems:first-child {
        flex: 1.9 1 0;
    }
    .list-infos__listitems + .list-infos__listitems {
        border-left: 1px solid $color-3--3;
        padding-left: 5%;
        margin-left: 5%;
    }
}

.list-infos__item {
    $list-infos__item__font-size: 1.4;
    margin: .5em 0;
    font-size: #{$list-infos__item__font-size}em;
    @include default-icons-absolute-before('\e053', em(1.4, $list-infos__item__font-size), $color-2--1, em(0.2, 1.4), inherit, inherit, 0);
    padding-left: em(2, $list-infos__item__font-size);
    a {
        color: $color-black;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    &.list-infos__phone, &.list-infos__fax {
        white-space: nowrap;
    }
    &.list-infos__name {
        &:before {
            content: "\e065";
        }
    }
    &.list-infos__address {
        &:before {
            content: "\e053";
        }
    }
    &.list-infos__website {
        &:before {
            content: "\e089";
        }
    }
    &.list-infos__phone {
        &:before {
            content: "\e02b";
        }
    }
    &.list-infos__fax {
        &:before {
            content: "\e09f";
        }
    }
    &.list-infos__email {
        &:before {
            content: "\e028";
        }
    }
    &.list-infos__infos {
        &:before {
            content: "\e083";
        }
    }
    &.list-infos__hours {
        &:before {
            content: "\e05b";
        }
    }
    &.list-infos__manager {
        &:before {
            content: "\e02e";
        }
    }
}
