.menu-cross {
    text-transform: uppercase;
    overflow: hidden;
    font-weight: $light;
    ul {
        padding-left: 125px;
    }

    li {
        vertical-align: top;
        display: inline-block;
        width: 100%;
        padding: .2em 1.5em;
        @include default-icons-absolute-before('\e011', 1.6em, $color-2--0, 0, inherit, inherit, 0);
        a {
            display: block;
            color: $color-white;
            text-decoration: none;
            font-size: 1.2em;
            line-height: 1.2em;
            &:hover, &:focus {
                color: $color-2--0;
                span {
                    text-decoration: underline;
                }
            }
        }
        &.active a {
            color: $color-2--0;
            text-decoration: none;
        }
        &:last-child {
            margin-top: 20px;
            &:after {
                content: "";
                height: 1px;
                width: 75px;
                position: absolute;
                background-color: $color-3--3;
                top: -11px;
                left: 0;
            }
        }
    }
}
