.reports {
    .title-1 {
        &:after {
            min-width: 10%;
        }
    }
    .reports__wrapper {
        position: initial;
    }
    .item__content .item__content--description {
        margin-bottom: 10px;
    }
    .reports__listitem {
        display: block;
    }
    .video:after {
        padding-left: 6px;
    }
    .reports__wrap {
        border-spacing: 0 0;
        text-align: center;
    }
    .reports__items--small img {
        width: 100%;
    }
    .link-view-2 {
        right: 0;
        top: 73px;
    }
}

