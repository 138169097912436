.menu-home {
    display: none;
    position: absolute;
    left: 50%;
    top: 240px;
    transform: translateX(-50%);
    z-index: 100;
    width: 610px;
    text-align: center;
    li {
        margin-top: 10px;
        display: inline-block;
        padding-right: 5px;
        .button-2.button-2--no-icon {
            padding-top: 1.2em;
            padding-bottom: 1.1em;
        }
    }
    .menu-home--active {
        .button-2 {
            background: $color-1--3;
            color: $color-white;
        }
    }
}
