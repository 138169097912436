.iam-box {
    .ddm__title {
        text-transform: uppercase;
    }
    .button-1 {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 1.6em;
        background: $color-white;
        border: 1px solid $color-black;
        &:hover, &:focus {
            color: $color-black;
            text-decoration: none;
            span {
                text-decoration: underline;
            }
        }
        &:before {
            content: url("../Images/images/je-suis.png");
            left: -14px;
            top: 12px;
            transform: none;
        }
        &:after {
            font-family: icons-default;
            content: "\e018";
            margin-left: 10px;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
        }
        &.button-1--big {
            padding: 0.9em 3em 0.9em 3em;
            margin-top: 10px;
        }
    }
    ul li {
        line-height: 1.7em;
        width: 112%;
    }
}

.ddm.iam-box__wrapper {
    .ddm__sub-level {
        display: block;
        visibility: hidden;
        top: 79px;
        left: -65px;
        right: inherit;
        overflow: hidden;
        z-index: 2;

    }
    .ddm__wrapper {
        background-color: $color-white;
        border: 1px solid $color-black;
        padding: 25px 25px;
        transform: translateY(-110%);
        transition: all ease .1s;
        width: 220px;
    }
    &.ddm--active {
        .ddm__sub-level {
            visibility: visible;
        }
        .ddm__wrapper {
            transform: translateY(0);
            transition: all ease .4s;
        }
    }
}

.iam__item {
    font-family: $typo-2;
    font-size: 1.3em;
    text-transform: uppercase;
    @include default-icons-before('\e017', 0 5px 0 0, em(1.4, 1.4), $color-2--0, em(-.2, 1.4));
    a {
        color: $color-black;
    }
}
