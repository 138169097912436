// on home page
.home {
    .news {
        overflow: hidden;
        background-color: $color-white;
        &.shadow__wrapper {
            padding: 0 0 0 0;
            padding-bottom: 30px;
            &:before {
                content: "";
                left: 10%;
                transform: rotate(-2deg);
                bottom: 58px;
                box-shadow: 0px 21px 15px 7px $color-3--2;
                z-index: 0;
            }
            &:after {
                content: "";
                right: 10%;
                transform: rotate(2deg);
                bottom: 58px;
                box-shadow: 0px 21px 15px 7px $color-3--2;
                z-index: 0;
            }
        }
    }

    .news__wrap.shadow__wrapper {
        background-color: $color-1--3;
        padding: 80px 0 80px 0;
        position: relative;
        z-index: 1;
        &:before {
            top: -30px;
            left: 10%;
            transform: rotate(-2deg);
            box-shadow: 0px 21px 15px 7px rgba(40, 40, 40, 0.4);
            z-index: 0;
        }
        &:after {
            top: -30px;
            right: 10%;
            transform: rotate(2deg);
            box-shadow: 0px 21px 15px 7px rgba(40, 40, 40, 0.4);
            z-index: 0;
        }
    }
}

.news {
    background-color: $color-1--3;
    padding-top: 8em;
    padding-bottom: 5em;
    .wrapper-main {
        padding: 0 0;
    }
    .news__wrap {
        margin-left: 0;
    }
    &.shadow__wrapper {
        padding: 80px 0 80px 0;
        &:before {
            content: none;
        }
        &:after {
            content: none;
        }
    }
    .title-1 {
        color: $color-white;
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
            margin: 0 3%;
        }
    }
    .bloc-news__title {
        a {
            color: $color-white;
        }
    }
    .news__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -1%;
        position: relative;
    }
    .bloc-news__item {
        flex: 1 1 18%;
        margin: 0 1%;
    }
    &.bloc-news {
        .bloc-news__title {
            a {
                &:hover, &:focus {
                    color: $color-2--0;
                }
            }
        }
    }
    .link-view-2 {
        position: absolute;
        right: 45px;
        top: -69px;
        .button-1 {
            background-color: $color-1--2;
            &:hover {
                background-color: $color-2--2;
            }
            &.button-1--no-text {
                background-color: $color-2--2;
                &:hover {
                    background-color: $color-1--2;
                }
            }
        }
    }
}

