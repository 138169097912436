.homePublications {
    .title-1:after {
        min-width: 3%;
    }
    .bloc-publications__title {
        &:before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .list-type-2__picture--2 {
        display: block;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
    }
    .bloc-publications__wrapper {
        display: block;
        width: 100%;
       text-align: center;
        margin-bottom: 20px;
    }
    .list-document-1__listitems {
        display: table;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .list-document-1__item--wrap {
            display: table-cell;
            margin-right: 10px;
        }
        .list-document-1__item {
            top: 47%;
        }
    }
    .newsletter {
        display: block;
        width: 100%;
        .newsletter__wrapper {
            display: block;
        }
        .newsletter__title {
        }
        input {
            width: 100%;
        }
        .newsletter__picture {
            left: 200px;
            top: 50px;
        }
        .newsletter__fields-wrapper {
            padding-bottom: 0;
            padding-top: 20px;
        }
    }
    .link-view-2 {
        right: -5px;
    }
}
