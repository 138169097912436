.logo {
    float: left;
    font-size: 1.3em;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-right: 20px;
    a {
        display: block;
    }
    img {
        height: auto;
        width: 100%;
    }
}
