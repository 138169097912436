.home-search {
    position: absolute;
    top: 10px;
    z-index: 100;
    background-color: $color-white;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 30px 10px 0px;
    min-width: 663px;
    .search-box__fields-wrapper {
        vertical-align: middle;
        padding-left: 40px;
    }
    .search-box__button-wrapper {
        vertical-align: middle;
    }
    .text-help {
        left: 140px;
    }
}
