// @name Homepage popin
.fancybox-lock .fancybox-overlay {
    overflow: hidden;
}

.popin-home {
    .fancybox-title {
        display: none;
    }
}

// @name Fullscren homepage popin
.popin-home--fullscreen {
    &.fancybox-wrap {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
    .fancybox-btn-close {
        position: absolute;
        top: 10px;
        right: 40px;
    }
    .fancybox-title {
        display: none;
    }
    .fancybox-inner {
        width: 100% !important;
    }
    .fancybox-inner, .fancybox-outer, .fancybox-skin {
        height: 100% !important;
    }
}

.popin {
    .section-main {
       // background: url(../Images/bg/bg-border-1.png) repeat;
    }

    .section-main__wrapper {
        padding-top: 0;
        padding-left: 35px;
        padding-right: 35px;
    }
    .rte {
        p {
            font-size: 1.6em;
        }
        h2 {
            font-weight: $light;
            font-size: 3.2em;
        }

    }
    .button-1 {
        font-size: 1.6em;
        background-color: transparent;
        color: $color-black;
        font-weight: $bold;
        margin-top: 10px;
        &:before {
            content: "\e000";
            background-color: $color-2--2;
            color: $color-1--3;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            padding-top: 10px;
            padding-left: 9px;
            left: 7px;
            font-size: 0.7em;
        }
    }

}
