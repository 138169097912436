.carrousel3 {
    .title-1 {
        &:before {
            flex: unset;
            min-width: 35%;
        }
        &:after {
            flex: unset;
            min-width: 30%;
        }
    }
    .nextCaroussel3 button {
        right: 0;
    }
    .prevCaroussel3 button {
        left: 0;
    }
}
