body.menu-main-1--active {
    overflow-y: hidden;
    .menu-main-1__listitems-0 {
        > .menu-main-1__item {
            overflow-y: auto;
            height: 100%;
        }
    }
}

.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: none;
        &[aria-hidden="false"] {
            display: block;
        }
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    z-index: 100;
    position: static;

}

.menu-main-1 {
    .wrapper-main {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

.menu-main-1__item {
    text-decoration: none;
}

.menu-main-1__item.menu-main-1__item--current {
    color: $color-1--3;
    text-decoration: none;
    background-color: $color-white;
    .menu-main-1__header a {
        color: $color-1--3;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        position: fixed;
        z-index: 101;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transition: transform ease .3s;
        transform: translate(100%, 0);
        width: 100%;
        background-color: rgba(0,0,0,0.5);
        > .menu-main-1__header {
            display: block;
            background: $color-white;
            padding: 1.5em 0;
            border-bottom: 1px solid $color-black;
            > span {
                display: block;
                text-transform: uppercase;
                font-size: 1.9em;
                color: $color-1--3;
                padding: 0 0 0 5.5rem;
                font-weight: $light;
            }
            .menu-main-1__action {
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                position: absolute;
                top: 1.3em;
                left: -7rem;
                width: 5rem;
                height: 5rem;
                background: $color-1--3;
                transition: left ease .3s, top ease .3s;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    content: "☰";
                    font-size: 1.3em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                    color: $color-white;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            &[aria-hidden="false"] {
                display: block;
            }
            > .menu-main-1__closing-button {
                display: block;
                top: 6px;
                right: 15px;
                font-size: 1.5em;
                color: $color-1--3;
            }
        }
        &.menu-main-1__item--active {
            transform: translate(0, 0);
            > .menu-main-1__header {
                .menu-main-1__action {
                    left: 0;
                    top: 0;
                    background: $color-white;
                    &:before {
                        color: $color-1--3;
                    }
                }
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    background: $color-white;
    > li {
        display: block;
        vertical-align: inherit;
        border-bottom: 1px solid $color-black;
        padding: 0 0 0 0;
        > .menu-main-1__header {
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 12px 0 12px;
                border-color: $color-black transparent transparent transparent;
                position: absolute;
                left: 32px;
                visibility: hidden;
                z-index: 2;
            }
            > a {
                color: $color-1--3;
                padding: 1.8em;
                position: relative;
                font-size: 1.8em;
                &:before {
                    content: none;
                }
                &:after {
                    position: absolute;
                    top: 50%;
                    right: .5rem;
                    transform: translateY(-50%);
                    content: "+";
                    font-size: 1em;
                    font-weight: $light;
                    display: block;
                    color: $color-2--2;
                    padding: 5px 20px 5px 20px;
                    border-left: 1px solid $color-black;
                }
                &[aria-expanded="true"] {
                    &:after {
                        font-family: icons-default;
                        content: "\e023";
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            position: static;
            max-height: inherit;
            display: none;
            margin-left: 0;
            width: 100%;
            .menu-main-1__listitems-1__wrapper {
                background: $color-white;
                transform: inherit;
                height: auto;
                padding: 0;
            }
            &[aria-hidden="false"] {
                display: block;
                max-height: inherit;
                .menu-main-1__listitems-1__wrapper {
                    transform: inherit;
                    transition: inherit;
                }
            }
            .menu-main-1__closing-button {
                visibility: hidden;
            }
        }
        &:hover, &:focus {
            background-color: $color-white;
        }
    }
    .menu-main-1__item--active {
        .menu-main-1__header {
            background-color: $color-black;
            &:after {
                visibility: visible;
            }
            a {
                color: $color-2--0;
            }
            a:before {
                content: none;
            }
            a:after {
                border-left: 1px solid $color-2--0;
            }
        }
        .menu-main-1__listitems-3 {
            .menu-main-1__header {
                background-color: $color-white;
                a {
                    color: $color-black;
                }
            }
        }

    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 100%;
    display: block;
    margin: 0;
   > li {
       flex: inherit;
       margin: 0;
        a {
            font-size: 1.6em;
            margin-right: 55px;
        }
        // Level 2
        > .menu-main-1__header {
            padding-bottom: 1.5em;
            padding-top: 1.5em;
            .menu-main-1__action {
                position: absolute;
                text-indent: -9999px;
                text-align: left;
                white-space: nowrap;
                display: block;
                top: 0.9em;
                right: 1.5em;
                width: 3rem;
                height: 3rem;
                z-index: 1;
                background-color: $color-1--3;
                border-radius: 50%;
                &:before {
                    position: absolute;
                    top: 2px;
                    left: 0px;
                    padding: 0.781em 0.49em;
                    font-size: 1.2em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                    font-family: icons-default;
                    content: "\e08a";
                    color: $color-white;
                    height: 1.5em;
                    width: 1.5em;
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e023"
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                padding-top: .5em;
                padding-bottom: .5em;
                padding-left: 1em;
                color: $color-black;
                margin-bottom: 0;
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
            }
        }
    }
    > .menu-main-1__item {
        > .menu-main-1__header {
            background-color: $color-white;
            a {
                color: $color-black;
                text-transform: none;
                text-decoration: none;
            }
        }
        &.menu-main-1__item--active {
            > .menu-main-1__header {
                background-color: $color-1--3;
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 12px 0 12px;
                    border-color: $color-1--3 transparent transparent transparent;
                    position: absolute;
                    left: 32px;
                    bottom: -11px;
                    z-index: 2;
                }
                .menu-main-1__action {
                    border: 1px solid $color-white;
                    &:before {
                        left: -1px;
                    }
                }
            }
            a {
                color: $color-white;
            }
        }
    }
}

// Level 3
.menu-main-1__listitems.menu-main-1__listitems-3 {
    padding: .5em 0;
    display: block;
    .menu-main-1__item {
        .menu-main-1__header {
            .menu-main-1__action {
                top: -.7rem;
                right: 2rem;
                width: 3rem;
                height: 3rem;
                &:before {
                    font-size: 16px;
                }
                &[aria-expanded="true"] {
                    border-color: $color-1--3;
                    background: $color-1--3;
                }
            }
        }
    }
}

// Level 4
.menu-main-1__listitems.menu-main-1__listitems-4 {
    padding: .5em 0;
    padding-left: 2em;
    .menu-main-1__item {
        .menu-main-1__header {
            background-color: $color-white;
        }
    }
    > li {
        padding-bottom: 0;
        a {
            font-weight: $normal;
        }
        .menu-main-1__header {
            a {
                padding-top: .3em;
                padding-bottom: .3em;
                padding-left: 1.7em;
            }
            &:before {
                font-family: icons-default;
                content: '\e027';
                position: absolute;
                top: 6px;
                left: 4px;
            }
            .menu-main-1__action {
                background-color: $color-2--0;
                &:before {
                    content: "\e08a";
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "\e023";

                    }
                }
            }
        }
    }
}
