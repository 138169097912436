// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        > .menu-main-1__header {
            .menu-main-1__action {
                top: 1em;
                left: -6rem;
            }
        }
    }
}
