// Focus
a, button, input[type="button"] {
    &:focus {
        outline: 1px dashed $color-1--2;
    }
}

// @name link-bloc
// @description Create an ergonomic link
.link-bloc__context {
    position: relative;
    .link-bloc {
        &:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: "";
            speak: none;
            z-index: 10;
        }
    }
}

// @name Link view
// @description Link under feature (RSS of the feature + list of the feature)
.link-view {
    $link-view__item__font-size: 1.2;
    .button-1 {
        &:before {
           content: '\e027';
        }
    }
    .button-1--no-text {
        padding: 1.45em 1.45em;
        &:before {
            content: "\e08a";
            font-size: 1.6em;
            left: 52%;
        }
    }
}
.link-view-2 {
    $link-view__item__font-size: 1.2;
    .button-1 {
        background: $color-2--2;
        &:hover {
            background: $color-1--3;
        }
        &:before {
            content: none;
        }
    }
    .button-1--no-text {
        padding: 1.45em 1.45em;
        background: $color-1--3;
        &:hover {
            background: $color-2--2;
        }
        &:before {
            content: "\e08a";
            font-size: 1.6em;
            left: 52%;
        }
    }
    .button-1--svg {
        margin-right: 5px;
    }
}

.column-bloc {
    .link-view {
        margin-top: 2em;
    }
}
