.carrousel3 {
    .listItems {
        display: flex;
        justify-content: center;
    }
    .item {
        text-align: center;
        display: inline-block;
        width: 250px;
    }
    .linkBloc {
        display: block;
        width: 100%;
        min-height: 140px;
        transition: background-color ease 0.3s, color ease 0.3s;
        position: relative;
        &:hover {
            .title {
                transition: background-color ease 0.5s, color ease 0.5s;
                background-color: $color-2--0;
                text-decoration: underline;
                color: $color-3--4;
            }
        }
    }
    .wrapImg {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .prevCaroussel3 {
        button {
            position: absolute;
            top: -15%;
            left: -15px;
            height: 140px;
            width: 20px;
        }
    }
    .nextCaroussel3 {
        button {
            position: absolute;
            top: -15%;
            right: -15px;
            height: 140px;
            width: 20px;
        }
    }
}

.surleWeb {
    margin-top: 7em;
    position: relative;
    .title-1 {
        &:before {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
        &:after {
            background: url("../Images/bg/bg-border-2.png") 100% 52% repeat-x;
        }
    }
    .surleWeb__wrapper {
        position: relative;
        margin-top: 4em;
        margin-bottom: 7em;
    }
    .title {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.2em;
        display: inline-block;
        background-color: $color-3--4;
        color: $color-2--0;
        min-height: 30px;
        min-width: 220px;
        text-align: center;
        padding-top: 8px;
        margin-top: 20px;
    }
    .link-view-2 {
        position: absolute;
        right: 0;
        top: -80px;
        .button-1--no-text {
            background-color: $color-2--0;
            &:hover {
                background-color: $color-1--3;
            }
        }
    }
}
