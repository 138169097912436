// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: none;
    margin-top: 7em;
}

.go-so-far__title {
    font-size: 3.4em;
    font-weight: $normal;
    color: $color-1--3;
    padding-bottom: 15px;
}

.go-so-far__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -2%;
    > * {
        flex: 1;
        margin: 0 2%;
        padding-left: 30px;
    }
}

.go-so-far__item--content {
    display: block;
    width: 230px;
    p {
        font-size: 1.6em;
        font-weight: $normal;
    }
    a {
        color: darken($color-2--3, 10%);
        &:hover, &:focus {
            color: $color-black;
        }
    }
}
