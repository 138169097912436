// @name Search box
// @description Search module
.search-box {
    position: relative;
    top: 0;
    .search-box__fields-wrapper {
        width: 100%;
        padding-left: 35px;
        .search-box__field-wrapper--in-line {
            input {
                width: 100%;
                display: block;
                top: 10px;
            }
            label {
                text-align: left;
                display: block;
            }

        }
    }
    .ddm .ddm__sub-level {
        padding-bottom: 20px;
        padding-right: 10px;
    }
    .text-help {
        left: 33px;
        transition: none;
        font-size: 1.2em;
        top: 26px;
    }
    .input-text:focus + .text-help {
        transition: none;
        top: 26px;
    }
}

.search-box.home-search {
    position: relative;
    top: 0;
    left: 50%;
    min-width: unset;
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .search-box__fields-wrapper {
        width: 100%;
        padding-left: 35px;
        .search-box__field-wrapper--in-line {
            input {
                width: 100%;
                display: block;
                top: 2px;
            }
            label {
                text-align: left;
                display: block;
            }

        }
    }
    .search-box__button-wrapper {
        padding-top: 10px;
    }
    // Drop down menu
    .ddm {
        > .ddm__sub-level {
            padding: 1em;
            background: $color-3--2;
        }
    }
    .text-help {
        left: 33px;
        transition: none;
        font-size: 1.2em;
        top: 26px;
    }
    .input-text:focus + .text-help {
        transition: none;
        top: 26px;
    }
}

.menu-main-1__wrapper {
    border-bottom: none;
}
