// @name TYPO3 default content style
.ce-left .ce-gallery, .ce-column {
    float: none;
}

.ce-right .ce-gallery {
    float: none;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 0;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 0;
}
