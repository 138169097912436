.list-type-1--event {
    .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
        display: block;
        vertical-align: inherit;
    }
    .list-type-1__picture {
        img {
            margin-right: 0;
        }
    }
    .list-type-1__wrapper-date {
        padding-right: 0;
        border-right: none;
        display: table;
        width: 100%;
        border-bottom: 1px solid $color-3--3;
        margin-bottom: 2em;
        .date-1, .hour-place {
            width: 50%;
            display: table-cell;
            vertical-align: middle;
            padding-bottom: 2em;
        }
        .date-1 {
            text-align: left;
            time:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }
    .list-type-1__wrapper {
        padding-left: 0;
    }
}
