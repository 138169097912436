//@name Page content

// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 3.4;
.rte h2, .rte .h2, .h2 {
    font-family: $typo-1;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $normal;
    color: $color-1--3;
    margin: em(6, $content-h2__font-size) 0 em(2.5, $content-h2__font-size) 0;
    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.rte h3, .rte .h3, .h3 {
    font-family: $typo-1;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $normal;
    color: $color-1--2;
    margin: em(5, $content-h3__font-size) 0 em(1.7, $content-h3__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2;
.rte h4, .rte .h4, .h4 {
    font-family: $typo-1;
    font-size: #{$content-h4__font-size}em;
    font-weight: $normal;
    line-height: 1.2;
    color: $color-black;
    margin: em(4, $content-h4__font-size) 0 em(1.8, $content-h4__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.rte h5, .rte .h5, .h5 {
    font-family: $typo-1;
    font-size: #{$content-h5__font-size}em;
    line-height: 1.7;
    font-weight: $normal;
    color: $color-3--3;
    margin: em(4, $content-h5__font-size) 0 em(1.8, $content-h5__font-size) 0;
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6, .rte .h6, .h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.5;
.rte {
    // @name Link
    a {
        color: $color-1--3;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    //a[href^="http://"]:not([href*="#{$domain}"]),
    //a[href^="https://"]:not([href*="#{$domain}"]),
    //a[href^="//"]:not([href*="#{$domain}"]) {
    //    @include default-icons-after('\e06d', 0 4px 2px 5px, 1em, $color-1--3, middle);
    //}

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $light;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-black;
        a {
            color: $color-1--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre__wrapper {
        background: url('../Images/bg/bg-border-1.png') repeat;
        padding: 10px 10px;
    }
    .encadre {
        $encadre__font-size: 1.5;
        padding: em(1.5, $encadre__font-size);
        font-size: #{$encadre__font-size}em;
        background: $color-white;
        margin: 0 0;
    }

    // @name Blockquote
    $content-blockquote__font-size: 1.8;
    $content-blockquote-icon-1__font-size: 3.2;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        @include default-icons-absolute-before('\e06f', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-1--2, 0, inherit, inherit, em(-6, $content-blockquote-icon-1__font-size));
        &:before {
            font-style: normal;
        }
        padding: em(0.5, $content-blockquote__font-size) 0 em(0.5, $content-blockquote__font-size) em(2, $content-blockquote__font-size);
        font-weight: $light;
        font-style: italic;
        margin: em(2.5, $content-blockquote__font-size) 0 em(2.5, $content-blockquote__font-size) em(6, $content-blockquote__font-size);
        color: $color-3--3;
        border-left: 1px solid $color-3--3;
        p {
            font-size: 1em;
            font-weight: $light;
        }
    }

    // @name Unordered list
    ul, .show-hide__content ul {
        margin: 2rem 0 1.5rem 2.5rem;
        list-style: none;
        font-size: #{$content-list__font-size}rem;
        font-weight: $light;
        color: $color-black;
        padding: 0;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            @include default-icons-absolute-before('\e01b', 1.1rem, $color-2--2, .3rem, inherit, inherit, 0);
            padding-left: 2rem;
            > ul:first-child, > a + ul { // for specificity selector
                margin: 0 0 1rem .8rem;
                list-style: none;
                padding: .1rem 0 .1rem 0;
                > li {
                    @include default-icons-absolute-before('\e007', 0.9rem, $color-black, .5rem, inherit, inherit, 0);
                    padding-left: 1.5rem;
                }
            }
            > ol:first-child, > a + ol { // for specificity selector
                margin: 0 0 1rem 1.5rem;
                padding: .1rem 0 .3rem 0;
                > li {
                    padding: 0;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: 2rem 0 1.5rem 2.5rem;
        padding: 0 0 0 1.8rem;
        list-style: decimal;
        font-size: #{$content-list__font-size}rem;
        color: $color-black;
        font-weight: $light;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding: 0;
            > ol:first-child, > a + ol { // for specificity selector
                margin: 0 0 1rem 1.5rem;
                padding: .1rem 0 .1rem 0;
                > li {
                    padding: 0;
                }
            }
            > ul:first-child, > a + ul { // for specificity selector
                margin: 0 0 1rem;
                list-style: none;
                padding: .1rem 0 .3rem 0;
                > li {
                    @include default-icons-absolute-before('\e01b', 1.1rem, $color-2--2, .3rem, inherit, inherit, 0);
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 4em 0;
    }
    .table-wrapper-inner {
        max-width: #{$content__max-width}em;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.3;
            font-family: $typo-1;
            text-align: left;
            font-weight: $light;
            font-style: italic;
            font-size: #{$table__caption_font-size}em;
            color: $color-3--3;
            margin-bottom: em(1.5, $table__caption_font-size);
        }
        th, td {
            padding: 12px 14px;
            border-right: 1px solid $color-2--2;
        }
        th {
            font-size: 1.3em;
            text-transform: uppercase;
            font-family: $typo-1;
            font-weight: $bold;
            color: $color-1--4;
            text-align: left;

        }
        th:first-child{
            border-right: 4px solid $color-2--2;
        }
        thead th {
            font-size: 1.3em;
            background: $color-white;
            font-weight: $bold;
            color: $color-1--3;
        }
        tbody th {
            text-align: right;
        }
        td {
            font-size: 1.4em;
            font-weight: $light;
            color: $color-black;
            background: $color-white;
        }
        tr:nth-child(2n) td,
        tr:nth-child(2n) th {
            background: darken($color-white, 1%);
        }
    }

    // @name column 2
    .column--2, .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
}
