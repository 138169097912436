.news {
   padding-left: 0;
    .title-1 {
        margin-left: 0;
        span {
            margin-right: 10px;
        }
        &:after {
            flex: unset;
            min-width: 7%;
        }
    }
    .bloc-news__item {
        display: block;
    }
    .bloc-news__title {
        width: 100%;
    }
    .bloc-news__picture img {
        width: 100%;
    }
    .link-view-2 {
        right: 0;
    }
}
