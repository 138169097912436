.shadow__wrapper {
    background-color: $color-3--1;
    padding: 10px 0 20px 40px;
    position: relative;
    margin: 0 0;
    &:after, &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 6px;
        z-index: -10;
    }
    &:after {
        bottom: 23px;
        right: 40px;
        transform: rotate(3deg);
        box-shadow: 0px 21px 15px $color-3--3;
    }
    &:before {
        bottom: 23px;
        left: 40px;
        transform: rotate(-3deg);
        box-shadow: 0px 21px 15px $color-3--3;
    }
}

.download {
    margin-bottom: 2em;
    max-width: 85rem;
    .download__title {
        font-size: 3.4em;
        font-weight: $normal;
        color: $color-1--3;
        padding-bottom: 15px;
    }
    .list-document-1__listitems {
        &:before {
            content: "";
            position: absolute;
            height: 80%;
            width: 1px;
            top: 24px;
            left: 395px;
            background-color: $color-3--5;
        }
    }
    .list-document-1__listitems {
        column-count: 2;
        li:before {
            display: table-cell;
            background-color: transparent;
            font-family: icons-project;
            font-size: 2.4em;
            color: $color-2--2;
            line-height: 2;
        }
        .pdf {
            &:before {
                content: "\E008";
            }
        }
        .doc {
            &:before {
                content: "\E009";
            }
        }
        .jpg {
            &:before {
                content: "\E00A";
            }
        }
        .xls {
            &:before {
                content: "\E00B";
            }
        }
        .ppt {
            &:before {
                content: "\E00C";
            }
        }
        .zip {
            &:before {
                content: "\E00D";
            }
        }
    }
    .list-document-1__item {
        display: table;
        text-transform: none;
        font-size: 1.6em;
        font-weight: $normal;
        margin: 0 0;
        a {
            display: table-cell;
            padding-left: 10px;
            vertical-align: middle;
            &:hover {
                color: $color-black;
            }
        }
    }
    .list-document-1__file-size {
        padding-left: 0;
        font-size: 0.8em;
        font-weight: $normal;
        &:before {
            content: "\e007";
            font-family: icons-default;
            position: relative;
            padding-right: 2px;
        }
    }
}
