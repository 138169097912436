.team {
    margin: 4rem 0;
}

.team__wrapper + .team__wrapper {
    margin-top: 3rem;
}

.team__title {
    font-size: 3.4em;
    font-weight: $normal;
    color: $color-1--3;
    padding-bottom: 15px;
}

.team__listitems {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -10px;
    > * {
        flex: 0 0 calc(50% - 20px);
        margin: 0 10px;
    }
}

.team__item {
    margin-bottom: 2rem;
}

.team__picture {
    float: left;
    img {
        margin-right: 2rem;
        margin-bottom: 1rem;
    }
}

.team__infos {
    overflow: hidden;
}

.team__title-2 {
    font-size: 2.2rem;
    font-family: $typo-1;
    font-weight: $light;
    color: $color-1--3;
}

.team__function {
    font-size: 1.4rem;
    font-style: italic;
    color: $color-3--3;
}

.team__teaser {
    font-size: 1.4rem;
    font-weight: $light;
    margin-bottom: 1.5rem;
}
