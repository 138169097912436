// @name Title 1
// @description Styling for title 1
.title-1 {
    $title--1__font-size: 3;
    font-size: #{$title--1__font-size}em;
    span {
        margin-right: 5px;
    }
    &:before {
        content: none;
    }
    &:after {
            display: block;
            content: "";
            min-height: 5px;
            min-width: 40px;
            margin: 0 1%;
    }
}
