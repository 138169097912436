// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4;
.title-1 {
    font-family: $typo-1;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $light;
    color: $color-1--3;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -1%;
    padding: 0 10%;
    margin-bottom: 30px;
    width: 100%;
    span {
        white-space: nowrap;
    }
    &:before {
        content: "";
        flex: 1 1 31.3333%;
        display: block;
        min-height: 5px;
        min-width: 20%;
        margin: 0 1%;
    }
    &:after {
        flex: 1 1 31.3333%;
        display: block;
        content: "";
        min-height: 5px;
        min-width: 20%;
        margin: 0 1%;
    }
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.5;
.title-2 {
    font-family: $typo-3;
    font-size: #{$title--2__font-size}em;
    font-weight: $bold;
    color: $color-2--3;
    text-transform: uppercase;
    margin: em(1, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
    font-family: $typo-1;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-3--3;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    padding-bottom: em(1.6, $title--3__font-size);
    border-bottom: 1px solid $color-2--4;
}
