.homePublications {
    .wrapper-main {
        padding: 0 1em;
    }
    .title-1 {
        &:before {
            flex: unset;
            min-width: 35%;
        }
        &:after {
            flex: unset;
            min-width: 20%;
        }
    }
    .publications__wrap {
        display: block;
        width: 100%;
    }
    .list-type-2__picture--2 {
        width: 20%;
    }
    .bloc-publications__wrapper {

    }
    .newsletter {
        display: table;
        width: 100%;
        margin-top: 20px;
        .newsletter__wrapper {
            display: table-cell;
            vertical-align: middle;
            padding-bottom: 20px;
        }
        .newsletter__title {
            top: 35px;
        }
        input {
            width: 60%;
        }
        .newsletter__picture {
            left: 200px;
            top: 50px;
        }
        .newsletter__fields-wrapper {
            padding-bottom: 0;
            padding-top: 20px;
        }
    }
}
