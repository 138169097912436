.services-box {
    float: right;
    .ddm {
        position: static;
        .button__background {
            background: url('../Images/bg/bg-border-1.png');
            height: 125px;
            width: 125px;
            border-radius: 50%;
            position: relative;
            .svg-icon {
                position: relative;
                z-index: 2;
                width: 40px;
                left: 0;
                top: -15px;
                transition: fill ease .3s;
            }
        }
        &.services-box__wrapper {
            position: fixed;
            right: -13px;
            top: 37vh;
            z-index: 200;
            .ddm__sub-level {
                display: block;
                visibility: hidden;
                top: 80%;
                left: -340px;
                right: inherit;
                overflow: hidden;
                z-index: -1;

            }
            .ddm__wrapper {
                background: url('../Images/bg/bg-border-1.png') repeat ;
                padding: 25px 25px;
                transform: translateY(-110%);
                transition: all ease .1s;
                padding: 7px;
            }
            .services__listitems__wrapper {
                background-color: $color-white;
                padding: 20px;
            }
            &.ddm--active {
                .ddm__sub-level {
                    visibility: visible;
                }
                .ddm__wrapper {
                    transform: translateY(0);
                    transition: all ease .4s;
                }
                .ddm__title {
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 125px;
                        bottom: -2px;
                        right: 0px;
                        border: 10px solid #fff;
                    }
                }
            }
            &:hover {
                .ddm__button {
                    height: 110px;
                    width: 110px;
                    transition: background ease .3s,  height ease .3s, width ease .3s;
                    background: $color-1--3;
                    text-decoration: underline;
                    span {
                        color: $color-white;
                        transition: color ease .3s;
                    }
                    .svg-icon{
                        fill : $color-white;
                        transition: fill ease .3s;
                    }
                }
            }
        }
        .ddm__title {
            span {
                font-family: $typo-3;
                font-size: 0.8em;
                font-weight: $bold;
                text-transform: uppercase;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                top: 69%;
                color: $color-1--3;
                white-space: normal;
                transition: color ease .3s;
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
        .ddm__button {
            font-family: $typo-1;
            font-weight: $bold;
            font-size: 1.6em;
            background: $color-white;
            height: 10rem;
            width: 10rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: background ease .3s, height ease .3s, width ease .3s;
            &:before {
                content: none;
            }
        }
        ul li {
            line-height: 1.7em;
        }
        .button-2 {
            margin-top: 20px;
        }
        .services__listitems {
            width: 400px;
        }
        .services__item {
            font-family: $typo-1;
            font-weight: $normal;
            font-size: 1.6em;
            @include default-icons-before('\e017', 0 5px 0 0, em(1.4, 1.4), $color-black, em(-.2, 1.4));
            a {
                color: $color-black;
            }
        }
    }
}
