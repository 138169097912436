.top-of-content__wrapper {
    border: none;
}

.ce-left .ce-gallery, .ce-column {
    float: left;
}

.header {
    order: 1;
    border-bottom: 1px solid $color-1--3;
    justify-content: flex-start;
    margin: 0 2rem 10rem 2rem;
}
.slider-home__wrapper {
    order: 2;
}

.section-main__wrapper-2 > * {
    display: block;
}

.section-main__aside {
    margin-top: 20px;
}

.top-of-content {
    display: none;
}
